* {box-sizing: border-box;margin: 0;padding: 0;}
body {float: left;width: 100%;margin: 0;padding: 0;box-sizing: border-box;position: relative;overflow: hidden;background-color: #F8F9FA !important;}
.wrapper {float: left;width: 100%;overflow: hidden;}
.sidebarWrapper.close, .navLogo.close {width: 53px !important;}
.panelwrapper.active.close {width: calc(100% - 53px);}
.btn_close {padding: 0px 10px !important;font-size: 20px !important;outline: none !important;box-shadow: none !important;border: 0px !important;}
#confimr_order_main {margin-top: 0px !important;}
.table>thead {position: sticky;left: 0;top: 0;z-index: 9;box-shadow: 0px 1px 0px 0px #dee2e6;}
.custom-thumb-vertical {z-index: 9;}

/* new dashboard css start here */
.DashboardMainHeader {float: left;width: 100%;display: flex;align-items: center;justify-content: space-between;column-gap: 20px;position: relative;margin: 0 0 18px;}
.DashboardMainHeader h4 {float: left;width: auto;margin: 0px;font-size: 16px;}
.DashboardMainHeader .MainHeader_filters {float: left;width: auto;display: flex;align-items: center;justify-content: center;column-gap: 10px;}
.DashboardMainHeader .MainHeader_filters select {height: 32px !important;font-size: 13px;border: 0px !important;border-radius: 4px;box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important;outline: none !important;padding: 5px 20px 5px 10px;cursor: pointer;background-image: url("../img/caret-down.svg");background-size: 10px;background-repeat: no-repeat;background-position: 93% center;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.DashboardMainHeader .MainHeader_filters .searchBoxwithbtn {width: 240px;border-radius: 4px;overflow: hidden;box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important;}
.DashboardMainHeader .MainHeader_filters .searchBoxwithbtn input {height: 32px !important;font-size: 13px;border: 0px !important;border-radius: 4px;outline: none !important;padding: 5px 38px 5px 30px;}
.DashboardMainHeader .MainHeader_filters .searchBoxwithbtn .searchInfo {top: 7px;line-height: normal;left: 8px;font-size: 14px;}
.DashboardMainHeader .MainHeader_filters .searchBoxwithbtn .searchbtn {background: transparent !important;border: 0px !important;outline: none !important;}
.DashboardMainHeader .MainHeader_filters .searchBoxwithbtn .searchbtn img {width: 16px;}
.DashboardMainHeader .MainHeader_filters .searchBoxwithbtn .searchclear {top: 6px;right: 34px;}
.DashboardMainHeader .MainHeader_filters .datepickField_new {width: 220px;}
.DashboardMainHeader .MainHeader_filters .downloadfilesBtn {border-radius: 4px;width: auto;padding: 4px 5px;}
.DashboardMainHeader .MainHeader_filters .downloadfilesBtn.downloadPdf {background-color: #158149;}
.DashboardMainHeader .MainHeader_filters .downloadfilesBtn.exportExcel {background-color: #C10C0B;}
.DashboardMainHeader .MainHeader_filters .downloadfilesBtn img {width: 21px;}
.DashboardMainHeader .MainHeader_filters .selectMultiselect {float: left;border-radius: 4px;box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important;}
.DashboardMainHeader .MainHeader_filters .selectMultiselect .godown-select__control {border: 0px !important;}
.dashboard_Main_section {float: left;width: 100%;}
.dashboard_wrapper {float: left;width: 100%;box-sizing: border-box;}
.iframe_dashboard_wrapper {float: left;width: 100%;}
.dashboard_cards {float: left;width: 100%;border: 1px solid #D9D9D1;border-radius: 8px;padding: 15px 12px;position: relative;-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.dashboard_cards:hover, .dashboard_cards:focus {box-shadow: 3px 3px 0px 0px #ddd;-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.dashboard_cards .dashboard_cards_Image {float: left;width: 48px;height: 48px;display: flex;align-items: center;justify-content: center;border-radius: 100%;margin: 0 0 17px;}
.card_wrapper.Monthly .dashboard_cards .dashboard_cards_Image {background-color: #E0EEFF;}
.card_wrapper.Today .dashboard_cards .dashboard_cards_Image {background-color: #A6C5E9;}
.dashboard_cards h5, .dashboard_cards h2, .dashboard_cards p {float: left;width: 100%;}
.dashboard_cards h5 {font-size: 14px;}
.dashboard_cards h2 {float: left;width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 28px;margin: 0 0 6px;}
.dashboard_cards p {margin: 0px;display: flex;align-items: center;font-size: 13px;color: #606060;column-gap: 3px;line-height: normal;}
.dashboard_cards p .IncreDeacrement_Percentage {float: left;width: auto;}
.dashboard_cards p .IncreDeacrement_Percentage .badge {border-radius: 30px;padding: 7px 8px 7px 8px !important;position: relative;font-size: 12px !important;font-weight: 500 !important;}
.dashboard_cards p .IncreDeacrement_Percentage .badge.bg-success {background-color: #D1FADF !important;color: #12CE70 !important;}
.dashboard_cards p .IncreDeacrement_Percentage .badge .bi {margin-right: 1px;}
.Monthly_heading {float: left;width: 100%;text-align: center;padding: 11px;font-size: 15px;border-radius: 8px;margin: 0 0 12px;}
.Monthly_heading.Monthly {background-color: #E0EEFF;}
.Monthly_heading.Today {background-color: #A6C5E9;}
.card_wrapper {float: left;width: 100%;margin-bottom: 14px;position: relative;}
.IncreDeacrement_Percentage_No_Badge {display: flex;align-items: center;column-gap: 4px;}
.IncreDeacrement_Percentage_No_Badge.success .bi {color: #12CE70 !important;}
.IncreDeacrement_Percentage_No_Badge.danger .bi {color: #FC5379 !important;}
.dashboard_common_tables {float: left;width: 100%;position: relative;}
.dashboard_Main_section {float: left;width: 100%;height: 100%;background-color: #fff;padding: 13px;}
.dashboard_common_tables .tableView thead tr th {padding: 12px 5px;background-color: #F8F9FD;}
.dashboard_common_tables .tableView tbody tr td {padding: 12px 5px;}
.warranty_registartions_columns {float: left;width: 100%;text-align: center;position: relative;}
.warranty_reg_cols {float: left;width: 100%;padding: 20px 15px;border-radius: 8px;color: #000;font-weight: 400;margin: 0 0 17px;border: 1px solid #D9D9D1;-webkit-transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -ms-transition: 0.5s; transition: 0.5s;}
.warranty_reg_cols:focus, .warranty_reg_cols:hover {box-shadow: 5px 5px 0px 0px #ddd;-webkit-transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -ms-transition: 0.5s; transition: 0.5s;}
.warranty_reg_cols p {font-size: 15px;letter-spacing: 0.4px;margin: 0 0 13px;}
.warranty_reg_cols h4 {margin: 0px;font-size: 25px;text-align: center;display: inline-block;}
.warranty_reg_cols h4 .IncreDeacrement_Percentage .bi {font-size: 20px;}
.label_only_percantage_set .IncreDeacrement_Percentage {float: none;text-align: center;width: auto;margin: 0 auto;display: inline-block;}
.react-datepicker-popper {z-index: 99 !important;}
/* new dashboard css end here */

.dashboard-table-center .table{text-align: center;}
.sidebarWrapper.close, .navLogo.close {width: 53px !important;}
.dashboard_icon_main {float: left;width: 17px !important;filter: invert(72%) sepia(12%) saturate(14%) hue-rotate(1deg) brightness(86%) contrast(83%);}
#confimr_order_main {margin-top: 0px !important;}
.adminmain {float: left;width: 100%;height: 100%;padding: 8px 9px;box-sizing: border-box;}
.profilUser h4 {font-size: 14px;margin: 0px;}
.profilUser small {text-transform: capitalize;font-size: 12px;}
.profilUser {float: left;width: auto;line-height: 8px;text-align: left;color: #718096;}
.dropdownHeader .dropdown-toggle::after {display: none;}
.permissionmodel h5 {font-size: 17px;margin: 0px;}
.permissiontableView tr td:first-child, .permissiontableView tr th:first-child {text-align: left;}
.permissiontableView td, .permissiontableView th {text-align: center;}
.permissionCenter {float: left;width: 100%;text-align: center;height: calc(100vh - 99px);display: flex;align-items: center;justify-content: center;}
.permissionCenter .errorMessageIcon {font-size: 19px;}
.permissiontableView tbody tr th.labeltd {font-size: 13px;font-weight: 400;padding: 10px 10px;background-color: #f6f6f6 !important;color: #212529;position: relative;}
.searchInfo {position: absolute;top: 7px;left: 10px;background-color: #fff;font-size: 15px;cursor: pointer;}

/* Targeting WebKit-based browsers */
.ScrollbarsSidebar_mains {overflow-x: auto;white-space: nowrap;}
.Bigform_custom_scroll::-webkit-scrollbar, 
.search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar,
.ScrollbarsSidebar_mains::-webkit-scrollbar, .dashboard_Main_section .MainHeader::-webkit-scrollbar {width: 4px;height: 4px;}
.Bigform_custom_scroll::-webkit-scrollbar-track, 
.search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar-track,
.ScrollbarsSidebar_mains::-webkit-scrollbar-track, .dashboard_Main_section .MainHeader::-webkit-scrollbar-track {background-color: #f1f1f1;}
.Bigform_custom_scroll::-webkit-scrollbar-thumb, 
.search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar-thumb,
.ScrollbarsSidebar_mains::-webkit-scrollbar-thumb, .dashboard_Main_section .MainHeader::-webkit-scrollbar-thumb {background-color: rgba(0, 0, 0, 0.2);border-radius: 6px;}
.Bigform_custom_scroll::-webkit-scrollbar-thumb:hover, 
.search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar-thumb:hover,
.ScrollbarsSidebar_mains::-webkit-scrollbar-thumb:hover, , .dashboard_Main_section .MainHeader::-webkit-scrollbar-thumb:hover {background-color: #555;}
#viewmore_btn {background-color: #E0EEFF !important;padding: 5px 15px;font-size: 12px;color: #333 !important;border: 1px solid #DEE2E5 !important;width: auto !important;float: right;margin: 5px 10px 0px 0px;border-radius: 4px;}

:root {
--loginColor: #0E0E30;
--maincolor: #0e39b6;
}

/* sidebar css */
.sidebarWrapper {float: left;width: 265px;height: calc(100vh - 18px);position: relative;background-color: #fff;border-radius: 8px;overflow: hidden;box-shadow: 0px 3.5px 5.5px 0px #00000005;}
.sidebarMain {float: left;width: 100%;position: relative;padding: 11px 11px;}
.sidebarMain .flex-column {overflow: hidden;display: flex;flex-direction: row !important;justify-content: flex-start;column-gap: 6px;}
.sidebarMain .nav-link {float: left;width: 18%;padding: 13.1px 10px;background-color: #F8F9FA;text-align: center;display: flex;justify-content: center;cursor: pointer;height: 43px;border-radius: 8px;}
.sidebarMain .nav-link img {object-fit: fill;object-position: center;width: 22px;}
.sidebarMain .nav-link:hover, .sidebarMain .nav-link.active {background-color: #E0EEFF;}
.sidebarMain .nav-link:hover img,
.sidebarMain .nav-link.active img {filter: invert(13%) sepia(80%) saturate(7263%) hue-rotate(201deg) brightness(86%) contrast(114%);}
.head_section {float: left;width: 100%;height: auto;display: flex;justify-content: space-between;align-items: center;padding: 0px 15px 0px 0px;margin: 0 0 10px;}
.head_section a.nav-logo {float: left;width: auto;}
.head_section a.nav-logo img {width: 150px;}
.head_section a.nav-logo.closed img {width: 40px;}
.head_section .navLogo.close {padding-left: 6px;}
.dropdownHeader {float: right;width: auto;}
.dropdownHeader .dropdown i{margin-right: 4px;font-size: 22px;color: #718096;}
.dropdownHeader .dropdown .dropdown-toggle {padding: 0px;background: transparent !important;color: #333 !important;border: 0px;border-radius: 0px;line-height: normal;box-shadow: none !important;outline: none !important;display: flex;align-items: center;column-gap: 6px;}
.dropdownHeader .dropdown .dropdown-toggle .AvatarTitle {float: left;width: 30px;height: 30px;font-size: 14px;color: #fff;border-radius: 22%;display: flex;align-items: center;justify-content: center;font-weight: 500; }
.sidebarWrapper .sidebar {float: left;width: 100%;height: 100%;padding: 0px 0px 0px 11px;background-color: #fff;}
.sidebarWrapper .sidebar .flex-column .nav-link {padding: 14px 0px;color: #000;font-size: 15px;line-height: normal;display: flex;column-gap: 10px;align-items: center;justify-content: flex-start;position: relative;}
.sidebarWrapper .sidebar .flex-column .nav-link span {float: left;width: auto;position: absolute;right: 10px;top: 15px;font-size: 12px;}
.sidebarWrapper .sidebar .flex-column .nav-link .menu-item-arrow {transform: rotate(179deg);position: absolute;right: 0;width: 15px;}
.sidebarWrapper .sidebar .flex-column .nav-link.active .menu-item-arrow {transform: none;}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul {padding: 0px;margin: 0px;}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li {float: left;width: 100%;font-size: 14px;list-style: none;}
.sidebarWrapper .sidebar .ScrollbarsSidebar .view {padding-right: 11px !important;overflow-x: hidden !important;}
.navLogo {float: left;width: 100% !important;padding: 20px 10px 20px;display: flex;align-items: center;justify-content: center;position: relative;}
.navLogo::before {content: "";float: left;width: 50%;height: 1px;background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #E0E1E2);position: absolute;left: 0;bottom: 0;}
.navLogo::after { content: ""; float: left; width: 50%; height: 1px; background-image: linear-gradient(to right,#E0E1E2, rgba(255, 0, 0, 0)); position: absolute; right: 0; bottom: 0; }
.navLogo img { width: 175px; }
.pageTitle { float: left; width: auto; text-transform: capitalize; flex: 1; font-size: 16px; margin: 0px; }
.Logout_btn { float: left; width: 100%; font-size: 20px; text-align: center; position: absolute; bottom: 10px; left: 0; right: 0; cursor: pointer; }
.Logout_btn i { color: #dc3545 !important; }
/* sidebar css */

/* login page css start here */
.loginWrapper { float: left; width: 100%; position: relative; height: 100vh; background-image: url("../img/X3_Product_Banner.webp"); background-repeat: no-repeat; background-position: center; background-size: cover; display: flex; align-items: center; justify-content: flex-start; padding: 0px 8rem; }
.login_main_wrapper { float: left; width: 410px; height: auto; background-color: #fff; padding: 25px 40px 40px; border-radius: 8px;}
.logoheader { color: #10b068; font-weight: 700; letter-spacing: 0.8px; margin: 0 0 14px; font-size: 39px; text-align: left; float: left; width: 100%; }
.logoheader img { width: 200px; }
.loginMainblog { float: left; width: 100%; }
.loginMainblog h4 { float: left; width: 100%; font-size: 20px; margin: 0 0 15px; }
.loginMainblog .formloginset .signInbtn { background-color: #004dac !important; border-color: #004dac !important; color: #fff !important; width: 100%; padding: 8px 10px; }
.mainloginwrps { float: left; width: 100%; height: 100%; }
.formloginset .form-label { float: left; width: 100%; font-size: 14px; }
.mb-3 { float: left; width: 100%; }
.formloginset input { border-radius: 4px; height: 38px; font-size: 13px !important; box-shadow: none !important; border-color: #DEE2E5; }
.widthfullbtn { width: 100%; }
.formloginset select { width: 100%; border-radius: 0px; height: 38px; font-size: 13px; padding: 10px 10px; border-color: #DEE2E5 !important; outline: none; box-shadow: none !important; background-image: url("../img/caret-down.svg"); background-size: 10px; background-repeat: no-repeat; background-position: 96% center; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
small.note { font-size: 13px; margin: 5px 0 0; color: #7a7a7a; }
.formloginset .btn { border-radius: 4px !important; position: relative; overflow: hidden; z-index: 9; background-color: #E0EEFF !important; border-color: #E0EEFF !important; color: #333 !important; }
.signInbtn::after {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: -1;content: "";background-color: #0E0E30;border-color: #0E0E30;transform: translateY(100%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.signInbtn:hover::after {transform: translateY(0%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.passwordfiled { float: left; width: 100%; position: relative; }
#searchbtn { margin: 0px; }
.passwordfiled input { padding-right: 35px; }
.passwordfiled .showpassbtn { width: 18px; height: 18px; position: absolute; right: 9px; top: 8px; cursor: pointer; }
.passwordfiled .showpassbtn svg { float: left; width: 100%; }
.danger-colorset { font-size: 12px; color: #F84646; float: left; width: 100%; margin: 4px 0 0; }
/* login page css end here */

/* pannel css */
.panelwrapper { float: left; width: calc(100% - 265px); padding: 0px 0px 0px 18px; position: relative; }
.panelContentwrapper { float: left; width: 100%; height: calc(100vh - 62px); border-radius: 8px; background-color: #fff; padding: 13px; box-sizing: border-box;box-shadow: 0px 3.5px 5.5px 0px #00000005;}
.panelContentwrapper_new { float: left; width: 100%; height: 100%;  }
.dashboard_main_wrapper { float: left; width: 100%; background-color: #fff; padding: 13px; position: relative; margin: 0 0 20px; border-radius: 8px; box-shadow: 0px 3.5px 5.5px 0px #00000005;}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a { float: left; width: 100%; text-decoration: none; color: #606060; padding: 13px 15px 13px 35px; font-size: 14px; position: relative; outline: none !important; border-radius: 8px; }
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:hover,
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a.active { background-color: #E0EEFF; }
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:after { content: ""; float: left; font-weight: 500; left: 14px; position: absolute; top: 21px; width: 5px; height: 5px; background-color: #606060; border-radius: 100%; }
#button-tooltip { padding: 0px !important; }
.sidebarWrapper .sidebar .flex-column.nav { padding-right: 20px; }
/* pannel css */

/* table filter */
.headerbtngroups { float: left; width: auto; align-items: center; display: flex; }
.export_btncmn { font-size: 14px; padding: 6px 14px; margin: 0 0 5px; position: relative; cursor: pointer; display: flex; align-items: center; background-color: var(--loginColor) !important; color: #fff !important; margin-left: 10px; }
.export_btncmn:hover { background-color: #2769ff !important; }
.graphs_columns { float: left; width: 100%; position: relative; margin: 5px 0 25px; }
.productQuantyset {float: left;width: auto;display: flex;align-items: center;border: 1px solid #ddd;border-radius: 10px;overflow: hidden;background-color: #eee;max-width: 130px;margin: -5px 0 0;}
.cart_wrappperr li .cartbtn_wraps .quantity_product .productQuantyset {width: 100%;display: flex;justify-content: space-between;background: transparent;border: 0px;font-size: 18px;}
.productQuantyset .btn {padding: 5px 5px;outline: none !important;border: 0px !important;margin: 0px 5px;}
.productQuantyset .btn i {color: #6e6c6c;}
.productQuantyBox {float: left;width: auto;word-break: break-all;}
.redmednstar {color: red;font-size: 19px;line-height: 10px;display: inline-block;position: relative;top: 3px;}
.addQuantity i {color: var(--loginColor) !important;}
.graphs_columns h4 {float: left;width: 100%;font-size: 17px;margin: 0 0 20px;}
.piechartwraps {float: left;}
.card-header-New {float: left;margin: 0 0 15px;width: 100%;}
.card-header-New select { height: 36px !important; font-size: 13px; border: 0px !important; border-radius: 4px; box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important; }
.card-header-New .userLimitselect { width: 70px !important; }
#exportbtnMain { background-color: #158149 !important; display: flex; align-items: center; justify-content: center; padding: 5px 7px !important; border-radius: 4px !important; box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06); }
#exportbtnMain img { width: 19px; line-height: normal; }
.searchBoxwithbtn { float: left; width: 260px; position: relative;    border-radius: 4px; overflow: hidden; box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06); }
.edittable img { width: 22px; }
.searchBoxwithbtn .form-control { border: 0px; border-radius: 0; box-shadow: none !important; font-size: 13px; height: 36px; padding: 7px 35px 7px 34px; }
.searchBoxwithbtn.search .form-control{padding-right: 55px;}
.searchBoxwithbtn .form-control:not( ~ .searchbtn ){padding-right: 0px!important;}
.searchBoxwithbtn .searchbtn, .searchBoxwithbtn .searchbtn:focus { position: absolute; right: 0px; top: 0px; border-radius: 0px !important; height: 100%; width: 32px; padding: 0px; display: flex; align-items: center; justify-content: center; box-shadow: none !important; background-color: transparent !important; border: 0px !important; }
.searchBoxwithbtn img { width: 17px; margin-right: 15px; }
.serachBoxWIth .searchbtn, .serachBoxWIth .searchbtn:focus { position: absolute; right: 0px; top: 0px; border-radius: 0px !important; height: 100%; width: 32px; padding: 0px; display: flex; align-items: center; justify-content: center; background-color: #004DAC !important; border-color: #004DAC !important; color: #fff !important; margin: 0 0 0 !important; }
.serachBoxWIth .searchbtn:hover { background-color: #0E0E30 !important; border-color: #0E0E30 !important; }
.serachBoxWIth .icon-tabler-search { width: 19px; }
.card-header-right {float: right;width: auto;display: flex;}
.activestatusselet, .userLimitselect {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 90px !important;}
.selectuserteamDrop {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 200px !important;}
.card-header-right select:first-child {margin-right: 10px;}
.card-header-right select:nth-child(2), .card-header-right select:nth-child(3) {margin-right: 10px;}
.card-header-right select:nth-last-child(2) {margin-right: 10px;}
.head_cmn_btn { font-size: 14px !important; border-radius: 4px !important; padding: 6.5px 9px !important; background-color: #F8F9FD !important; color: #4C4C4C; border-color: #F8F9FD !important; box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06); }
.head_cmn_btn:hover { background-color: #333 !important; border-color: #333 !important; color: #fff !important; }
.head_reset_btn, .head_reset_btn:focus, .head_reset_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: red !important;color: #fff !important;border-color: red !important;}
.head_reset_btn:hover {background-color: red !important;}
.cmn_export_btn, .cmn_export_btn:focus, .cmn_export_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: #00b13a !important;color: #fff !important;border-color: #00b13a !important;}
.cmn_export_btn:hover {background-color: #008d21 !important;}
.cmn_export_btn img {width: 18px;line-height: normal;margin: 0px 3px 0px 0px;}
.head_cmn_btn i {margin: 0 4px 0 0px;}
.head_cmn_btn img { width: 14px; float: left; margin: 4px 7px 0px 0; }
.head_cmn_btn:hover img { filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%); }
.head_reset_btn i {margin: 0 4px 0 0px;}
.searchBoxwithbtn .searchclear { float: right; width: auto; position: absolute; top: 9px; right: 40px; font-size: 13px; cursor: pointer; color: red; background-color: #fff; }
/* table filter */

/* table css  */
.tableView {float: left;width: 100%;position: relative;}
.tableView thead tr th {font-size: 13px;font-weight: 500;padding: 10px 10px;background-color: #F8F9FD;color: #212529;position: relative;border-bottom: 0px;}
.tableView thead tr th.active { color: #10b068; font-weight: 500; }
.tableView thead tr th.inactive { color: red; font-weight: 500; }
.gstreporttableadded tbody tr th { font-weight: 500; }
.gstreporttableadded thead tr th, 
.gstreporttableadded tbody tr td { white-space: nowrap; }
.Main_sectionGSTReports .card-header-New { width: auto; position: absolute; right: 0; top: 0; }
.Main_sectionGSTReports .datepickField input {background-position: 93% center;}
.tableView tbody {border-top: 0px !important;}
.tableView tbody tr td, .tableView tbody tr th {font-size: 13px;vertical-align: middle;color: #212529;}
.AvtarMain {float: left;width: auto;border-radius: 0.375rem;border: 1px solid #eee;overflow: hidden;}
.AvtarMain img {width: 60px;}
.table-hover>tbody>tr:hover>* {background-color: transparent !important;--bs-table-accent-bg: none !important;--bs-table-bg-state: transparent !important;}
.badge { font-weight: 500 !important; letter-spacing: 0.5px !important; border-radius: 100px !important; padding: 11px 22px !important; font-size: 13px !important; }
.badge.bg-success { background-color: #D0EAFF !important; color: #015BA5 !important; }
.badge.bg-danger { background-color: #FFD5D5 !important; color: #C70101 !important; }
.badge.bg-primary { background-color: #eaf3ff !important; color: #0d6efd !important; }
.edittable  {border-radius: 0px !important;padding: 0px !important;line-height: normal !important;font-size: 17px !important;box-shadow: none !important;background-color: transparent !important;color: #000 !important;border: 0px !important;outline: none !important;}
.edittable i {line-height: normal;}
.exportbtn { background-color: #E0EEFF !important; border-color: #E0EEFF !important; border-radius: 4px !important; position: relative; overflow: hidden; z-index: 9; }
.exportbtnCancel {z-index: initial !important;}
.cancel-reset-Btn { background-color: transparent !important;border-color: #dee2e5 !important; border-radius: 4px !important; position: relative; overflow: hidden; z-index: 9;color: #333 !important;}
.cancel-reset-Btn-New {padding: 8px 15px !important;}
.card-header-New.card-header-Filter-New .datepickField input { background-position: calc(100% - 15px) center; }
.card-header-New.card-header-Filter-New .userLimitselect { box-shadow: none !important; border: 1px solid #dee2e6 !important; }
.card-header-Order-Filter-New .datepickField input { background-position: calc(100% - 15px) center; }
.exportbtnMain {margin-right: 10px;font-size: 19px !important;padding: 2px 7px !important;margin: 0 7px 0 0px !important;line-height: normal;background-color: #10b068 !important;border-color: #10b068 !important;color: #fff !important;border-radius: 0px !important;width: auto !important;}
.exportbtn i {margin: 0 4px 0 0px;}
.skipbtn {background-color: transparent !important;border-color: #DEE2E5 !important;font-size: 14px !important;padding: 6.6px 20px !important;margin-right: 10px;color: #333 !important;}
#UploadCmnBTN .spinner-border {height: 16px;width: 16px;position: absolute;right: 9px;top: 9px;}
.addSalerperformBtn {padding: 7.5px 15px !important;border-radius: 4px !important;font-size: 14px !important;background-color: #E0EEFF !important;border-color: #E0EEFF !important;}
.addSalerperformBtn.salespersionClearbtn {background-color: transparent !important;border: 1px solid #dee2e5 !important;color: #333 !important;}
.inputBoxs.monthly_target_fields {border-radius: 0px;font-size: 13px;height: 38px;box-shadow: none !important;}
.salesFranchiseErrorModal .modal-dialog {max-width: 800px;}
/* table css  */

/* common toggle css */
.comnToggle {float: left;width: auto;}
.comnToggle i {font-size: 25px;cursor: pointer;line-height: normal;}
.comnToggle .bi-toggle2-on {color: #008fff;}
/* common toggle css */

/* form css */
.tabsWrappers { float: none !important; width: 100%; max-width: 75%; margin: 15px auto 0; display: table; padding: 0px 60px; position: relative; border-top: 0px; border-bottom: 0px; } 
.formloginset .form-label { float: left; width: 100%; font-size: 14px; } .mb-3 { float: left; width: 100%; } 
.formloginset textarea { border-radius: 4px; height: 120px; font-size: 13px; box-shadow: none !important; resize: none; } 
.formloginset .form-control[type=file] { line-height: 29px; } 
.widthfullbtn { width: 100%; } 
.formloginset select { width: 100%; border-radius: 4px; height: 38px; font-size: 13px; padding: 6px 10px; border-color: #ced4da; outline: none; box-shadow: none !important; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC); background-size: 10px; background-repeat: no-repeat; background-position: 96% center; -webkit-appearance: none; -moz-appearance: none; appearance: none; } 
.tablecollapsewraps { background-color: transparent !important; } 
.tablecollapsewraps table th, .tablecollapsewraps table td { font-size: 12px !important; padding: 8px 14px; color: #212529; } 
.tablecollapsewraps table th { font-weight: 400; background-color: #F8F9FD; } 
.tablecollapsewraps table tr:last-child th, .tablecollapsewraps table tr:last-child td { border-bottom: 0px; } 
.formData { float: left; width: 100%; font-size: 14px; } 
.formsearchList { float: left; width: 100%; border-bottom: 1px solid #eee; margin-bottom: 12px; } 
.formbtn { background-color: #E0EEFF !important;border-color: #E0EEFF !important;border-radius: 4px !important; padding: 8px 25px !important; margin: 5px 0 0 !important; position: relative; overflow: hidden; z-index: 9;color: #000 !important;} 
.order-pause-btn-set { background-color: #E0EEFF !important;border-color: #E0EEFF !important;border-radius: 4px !important; padding: 8px 25px !important; position: relative; overflow: hidden; z-index: 9;color: #000 !important;} 
.ToggleMainWrap { display: flex; float: left; width: 100%; align-items: center; justify-content: flex-start; column-gap: 10px; } 
.ToggleMainWrap_custom {display: flex;align-items: center;column-gap: 10px;}
.ToggleMainWrap_custom .mb-3 {margin-bottom: 0px !important;width: auto !important;}
.formloginset { float: left; width: 100%; position: relative; } 
.formloginset .HeaderDivider:nth-last-child(2) { margin: 19px 0 18px; } 
.HeaderDivider { float: left; width: 100%; padding: 30px 15px 10px 15px; border: 1px solid #eee; margin: 19px 0 30px; position: relative; border-radius: 4px; } 
.HeaderDivider h6.subtitleForm { float: left; width: auto; position: absolute; background-color: #f9fafb; padding: 10px 15px; font-size: 14px; top: -18px; left: 15px; margin: 0px; border: 1px solid #eee; border-radius: 4px; font-weight: 400; } 
.btnGroupswraps { float: left; width: 100%; display: flex; column-gap: 10px; margin-bottom: 10px; } 
.btnGroupswraps .Resetbtn { background-color: transparent !important; border-color: #E0EEFF !important; color: #000000 !important; border-radius: 4px !important; padding: 8px 15px !important; font-size: 14px !important; position: relative; overflow: hidden; z-index: 9; width: auto !important; } 
#GetDetailsbntn { margin-top: 0px !important; } 
.filtr_Form { float: left; width: 100%; margin-bottom: 15px; } 
.datepickField { float: left; width: 100%; position: relative; } 
.datepickField input { border-radius: 4px; height: 38px; width: 100%; font-size: 13px; box-shadow: none !important; border: 1px solid #dee2e6 !important; padding: .375rem .75rem; outline: none !important; background-image: url("../img/calendar.png"); background-repeat: no-repeat; background-size: 16px; background-position: 90% center; } 
.datepickField input { background-position: 96% center; } 
.Addbtn { background-color: #E0EEFF !important; border-color: #E0EEFF !important; padding: 8px 20px !important; font-size: 14px !important; position: relative; overflow: hidden; z-index: 9; width: auto !important; color: #000 !important;border-radius: 4px !important;} 
.react-datepicker-wrapper { width: 100%; } 
.card-header-New .formloginset input { height: 36px !important; } 
.totaltd th { background-color: #F8F9FD !important; color: #212529 !important; font-size: 13px; font-weight: 500; padding: 10px 10px !important; } 
.selectMultiselect .godown-select__input-container.css-qbdosj-Input, .selectMultiselect .godown-select__input-container { margin: 0px; padding-bottom: 0px; padding-top: 0px; } 
.selectMultiselect .godown-select__value-container.css-1fdsijx-ValueContainer { padding: 0px 8px !important; } 
.selectMultiselect .godown-select__control, .selectMultiselect .css-t3ipsp-control { border: 1px solid #DEE2E5 !important; border-radius: 4px; outline: none !important; box-shadow: none !important; } 
.selectMultiselect .css-13cymwt-control, .selectMultiselect .css-1jqq78o-placeholder, .selectMultiselect .css-1dimb5e-singleValue { font-size: 13px !important; } 
.formloginset #selectMultiselectBrand input{ height: 28px !important; } 
.formloginset .whloginhbtn{ width:100%; padding: 6px 9px !important; margin:0px !important; color: #333 !important; } 
.formloginset .getDetailButton{ width:100px; padding: 6px 7px!important; margin:0px !important } 
/* form css */

/* image upload  */
.uploadfielsbuttons .uploadfilebuttons {margin-bottom: 17px;}
.imageuploads {float: left;width: 100px;height: 100px;border-radius: 20px;position: relative;border: 1px solid #ddd;}
.imageuploads img {float: left;width: 100%;height: 100%;border-radius: 20px;object-fit: cover;object-position: center;}
.imageuploads .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -2px;top: -2px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.imageuploads .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}
.pdffileviewo .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -7px;top: -3px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.pdffileviewo .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}
.fileloader {float: left;width: 28px;height: 28px;position: absolute;right: -6px;background-color: #fff;border-radius: 100%;top: -5px;padding: 5px;box-shadow: 0px 0px 17px -3px #ccc;}
.fileloader img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.fileloader.active {padding: 0px;}
.uploadbuttons {float: left;width: 100px;height: 100px;position: relative;cursor: pointer;border: 1px solid #eee;border-radius: 20px;}
.uploadbuttons .uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;}
.uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;object-fit: cover;object-position: top;}
.uploadbuttons input.uploadfilInput {position: absolute;visibility: hidden;height: 100%;width: 100%;left: 0;top: 0;}
.uploadbuttons .fileloader label {float: left;width: 100%;height: 100%;cursor: pointer;}
.uploadbuttons .fileloader label img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.error_medotory {float: left;width: 100%;font-size: 13px;color: red;}
.uploadfilebox {float: left;width: 100%;height: 100%;}
/* image upload  */

/* side content css */
.MainHeader {float: left;width: 100%;margin-bottom: 15px;text-align: right;border-bottom: 1px solid #eee;height: 31px;} 
.dashboard_Main_section .MainHeader { overflow: auto; white-space: nowrap; } 
.dashboard_Main_section .MainHeader_Tab li:hover::after, .dashboard_Main_section .MainHeader_Tab li.active::after { bottom: -7px; } 
.MainHeader .backbtn {padding: 6px 10px;font-size: 13px;background: #f9fafb;box-shadow: none !important;text-decoration: none;color: #333 !important;line-height: normal;border-radius: 4px 4px 0px 0px;border-color: #eee !important;border-bottom: 0px;} 
.FranchiseWallet .backbtn { padding: 0px 10px; font-size: 13px; background: transparent !important; box-shadow: none !important; text-decoration: none; color: #333; } 
.selectMultiselect { float: left; width: 100%; position: relative; } 
.searchWrapper { min-height: 12px !important; padding: 3px 10px !important; border-radius: 0px !important; border: 1px solid #ddd !important; } 
.mendatory_star { color: red; } 
.multiSelectContainer { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC); background-position: 95%; background-repeat: no-repeat; background-size: 13px; } 
.multiSelectContainer input { height: 33px !important; margin-top: 0px !important; } 
.selectMultiselect.removeBackground .multiSelectContainer { background-image: none !important; } 
.chip { padding: 3px 9px !important; } 
.optionListContainer { z-index: 99 !important; } 
.MainHeader_Tab { float: left; width: auto; padding: 0px; margin: 0px; display: flex; align-items: center; column-gap: 15px; position: relative; } 
.MainHeader_Tab li { list-style: none; font-size: 15px; cursor: pointer; font-weight: 500; position: relative; } 
.MainHeader_Tab li:hover, .MainHeader_Tab li.active { color: #004DAC; } 
.MainHeader_Tab li:hover::after, .MainHeader_Tab li.active::after { content: ""; float: left; width: 100%; height: 3px; background-color: #004DAC; position: absolute; left: 0; bottom: -8px; } 
/* side content css */ 

.signInbtn .spinner-border { height: 21px; width: 21px; border-width: 3px; } 
.commonModal .notformsection { text-align: center; line-height: 28px; font-size: 17px; } 
.commonModal .notformsection .logoutIconbtn { font-size: 25px; color: red; } 
.commonModal .modal-dialog .modal-content { border-radius: 8px !important; } 
.commonModal .modal-dialog .modal-footer .btn { padding: 8px 20px !important;font-size: 14px !important;position: relative;overflow: hidden;z-index: 9;width: auto !important;color: #000 !important;border-radius: 4px !important;} 
.commonModal .modal-dialog .modal-footer .btn-secondary {background-color: transparent !important;border-color: #E0EEFF !important;color: #000000 !important;}
.commonModal .modal-dialog .modal-footer .addbtncmn  {background-color: #E0EEFF !important;border-color: #E0EEFF !important;} 
.commonModal.Update_stock_modal .modal-dialog {width: 430px;}
.commonModal .modal-dialog .modal-content .modal-header .btn-close { box-shadow: none !important; outline: none !important; } 
.modal-header .btn-close { box-shadow: none !important; } 
.addTableModal .modal-dialog{ max-width: 780px; } 
.StockInwardLogDetailsTable .modal-dialog{ max-width: 900px; } 
.OrderDetailsModal .notformsection { text-align: center; line-height: 28px; font-size: 17px; } 
.downloadaction { color: #333; font-size: 18px; float: right; } 
.productbilladdform { float: left; width: 100%; text-align: left; padding: 13px 20px; border: 1px solid #eee; border-radius: 10px; margin: 10px 0 0; } 
.btn_add { padding: 6px 10px; border: 1px solid #ddd; border-radius: 0px; outline: none !important; } 
.images_labels_wrapper { float: left; width: 100%; } 
.images_labels_wrapper li { float: left; width: 100%; position: relative; margin-bottom: 5px; } 
.images_labels_wrapper li .bi-trash3 { float: right; color: red; cursor: pointer; } 
.images_labels_wrapper li .delete_btn { float: right;width: 20px;cursor: pointer;}
.images_labels_wrapper.images_labels_wrapper_edit_point {padding: 0px;margin-bottom: 0px;}
.images_labels_wrapper.images_labels_wrapper_edit_point li {padding: 5px 10px !important;background-color: #f2f2f2 !important;border-radius: 4px !important;border: 1px solid #ddd !important;list-style: none;margin-bottom: 8px;font-size: 14px;}
.images_labels_wrapper.images_labels_wrapper_edit_point li:last-child {margin-bottom: 0px;}
.StockDetailsReportPage .card-header-New .card-header-right .exportbtn { display: none; } 
.OrderDetailsModal .notformsection .logoutIconbtn { font-size: 25px; color: red; } 
.OrderDetailsModal .modal-dialog .modal-content { border-radius: 8px !important; } 
.OrderDetailsModal .modal-dialog .modal-footer .btn-secondary {background-color: transparent !important;border-color: #E0EEFF !important;color: #000000 !important;border-radius: 4px !important;} 
#approvlaMargin, #commonbtnstyle { margin-top: 0px !important; } 
.isdemo_flags { font-size: 11px !important; font-weight: 500 !important; letter-spacing: 0.5px !important; padding: 6px 8px !important; border-radius: 4px; } 
#columnfullwith { width: 100% !important; } 
.OrderDetailsModal input { box-shadow: none !important; } 
.OrderDetailsModal .modal-dialog .modal-footer .addbtncmn  {border-radius: 4px !important;font-size: 15px;padding: 8px 18px;background-color: #E0EEFF;border-color: #E0EEFF;color: #000 !important;} 
.OrderDetailsModal .modal-dialog .modal-content .modal-header .btn-close { box-shadow: none !important; outline: none !important; } 
.OrderDetailsModal .modal-dialog { max-width: 65%; } 
.errorDetailsModal .notformsection { text-align: center; line-height: 28px; font-size: 17px; } 
.errorDetailsModal .notformsection .logoutIconbtn { font-size: 25px; color: red; } 
.errorDetailsModal .modal-dialog .modal-footer .btn-secondary { border-radius: 0px !important; font-size: 15px; padding: 8px 18px; } 
.errorDetailsModal .modal-dialog .modal-footer .addbtncmn  { border-radius: 0px !important; font-size: 15px; padding: 8px 18px; background-color: #10b068 !important; } 
.errorDetailsModal .modal-dialog .modal-content .modal-header .btn-close { box-shadow: none !important; outline: none !important; } 
#GetDetailsbntn.filterBlackbtn{ background-color: #0E0E30 !important; border-color: #0E0E30 !important; border-radius: 0px !important; padding: 7.5px 10px !important; margin: 0px 0 0 10px !important; position: relative; overflow: hidden; z-index: 9; width: auto !important; }

/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 10px 0px 0px;}
.paginationCustom li:first-child button,.paginationCustom li:last-child button,
.paginationCustom li:nth-child(2) button,.paginationCustom li:nth-last-child(2) button {color: transparent;position: relative;}
.paginationCustom li:first-child button::before,.paginationCustom li:nth-child(2) button::before,.paginationCustom li:last-child button::before,.paginationCustom li:nth-last-child(2) button::before {content: "";float: left;width: 100%;height: 100%;background-repeat: no-repeat;background-position: center;position: absolute;left: 0;top: 0;}
.paginationCustom li:first-child button::before {background-image: url("../img/paginations/First.svg");}
.paginationCustom li:nth-child(2) button::before {background-image: url("../img/paginations/Next.svg");transform: rotate(180deg);}
.paginationCustom li:nth-last-child(2) button::before {background-image: url("../img/paginations/Next.svg");}
.paginationCustom li:last-child button::before {background-image: url("../img/paginations/Last.svg");}
.paginationCustom li .rpb-item--active {background-color: #004DAC !important;border-color: #004DAC !important;color: #fff !important;}
.paginationCustom li button {width: 26px;height: 26px;min-width: 26px;font-size: 13px;border-radius: 8px;border: 1px solid #F1F1F1;}
/* pagination css end here */

.godown-select__menu {z-index: 9999 !important;} 
.Bigform_custom_scroll { float: left; width: 100%; overflow-x: hidden; overflow-y: auto; padding-right: 5px; } 
.selectMultiselectCategory .godown-select__input { height: 39px !important; } 
.godown-select-container .selectMultiselectBrand .godown-select__input { height: 40px !important; } 
.card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand { width: 240px; margin-right: 10px; } 
.card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand input, .card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand .godown-select__control{ height: 36px !important; min-height: 36px !important; } 
.card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand .godown-select__value-container { padding: 0px 8px !important; } 
/* Chrome, Safari, Edge, Opera */ 
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; } 
/* Firefox */ 
input[type=number] { -moz-appearance: textfield; } 
.tableView tbody .btn-link { padding: 0px; font-size: 13px; background: transparent !important; } 
.tableView tbody .btn-link-color { color: #0d6efd !important; cursor: pointer; font-weight: 400; } 
#activestatusselet { width: 100% !important; } 
#FranchiseUserReportWidth { width: 130px !important; } 
.commonModal.addBoxModalNew .modal-dialog { max-width: 680px; } 
#FranchiseUserReportWidthNew { width: 230px !important; } 
.newsumbitbnt  { background-color: #10b068 !important; color: #fff !important; font-size: 14px !important; border-radius: 0px !important; padding: 6.6px 10px !important; margin-right: 10px; } 
.table-description { height: 90px; width: 180px !important; border-radius: 0px !important; box-shadow: none !important; } 
.cancelBtn { background-color: #10b068 !important; color: #fff !important; font-size: 14px !important; border-radius: 0px !important; padding: 6.6px 10px !important; } 
.tableBolanchor { color: #00f; cursor: pointer; } 
.ScrollbarsSidebar .view { margin-right: -17px !important; padding-right: 20px; margin-bottom: 0px !important;overflow-x: auto !important;} 
.sotklevelbtn { float: right; position: relative; right: 20px; } 
.serachBoxWIth { float: left; width: 100%; position: relative; border-radius: 4px; overflow: hidden; } 
.serachBoxWIth .spinner-border { position: absolute; right: 40px; top: 9px; height: 20px; width: 20px; border-width: 3px; } 
.defaultvalueField { float: left; width: 100%; border: 1px solid #DEE2E5; height: 38px; display: flex; align-items: center; padding: 10px; font-size: 13px; cursor: no-drop; background-color: #F8F9FD; border-radius: 4px;text-transform: capitalize;}
.defaultvalueFieldaddress { float: left; width: 100%; border: 1px solid #DEE2E5; padding: 10px; font-size: 13px; cursor: no-drop; background-color: #F8F9FD; border-radius: 4px; } 
.defaultvalueFieldaddress ul { padding-left: 15px; margin-bottom: 0px; } 
.defaultvalueFieldaddress ul li { margin-bottom: 3px; font-size: 13px; } 
.warehouse_Approval_details #cancelOrderSet {background-color: transparent !important;border-color: #dee2e5 !important;color: #333 !important;}
.defaultvalueFieldaddress ul li:last-child { margin-bottom: 0px; } 
.pincode_error { float: left; width: auto; position: absolute; right: 42px; top: 6px; cursor: pointer; } 
.filedinfomsg { font-size: 12px; float: right; margin-right: 10px; cursor: pointer; } 
.UploadCmnBTN { position: relative; } 
.UploadCmnBTN input[type="file"] { position: absolute; left: 0; top: 0; visibility: hidden; } 
.leftFormheader { float: left; width: inherit; } 
#UploadCmnBTN { background-color: #E0EEFF !important;border-color: #E0EEFF !important;border-radius: 4px !important;font-size: 14px !important;padding: 7.5px 10px !important;position: relative;color: #333 !important;box-shadow: none;} 
.UploadLoader { float: left; width: auto; margin: 5px 0px 0px 10px; } 
.UploadLoader .spinner-border { height: 23px; width: 23px; border-width: 4px; } 
.uplaodedFilename {float: left;width: auto;padding: 9.5px 40px 9.5px 34px;font-size: 13px;word-break: break-word;background-color: #f2f2f2;border-radius: 4px;border: 1px solid #ddd;display: flex;align-items: center;position: relative;line-height: normal;} 
.exsting_newdetails { float: left; width: 100%; margin: 15px 0 0; } 
.exsting_newdetails h4 {font-size: 15px;margin: 0 0 12px;} 
.uplaodedFilename i:first-child { font-size: 20px; position: absolute; left: 8px; } 
.uplaodedFilename .bi-x-circle-fill { color: red; position: absolute; right: 10px; top: 8px; z-index: 99; background-color: #fff; line-height: normal; border-radius: 100%; font-size: 18px; cursor: pointer; height: 19px; width: 19px; } 
.uplaodedFilename .bi-x-circle-fill::before { vertical-align: baseline; } 
.ScrollbarsSidebarHide .formloginset { padding: 0px 15px 0px 0px; } .btn.disabled { cursor: no-drop !important; } .actionbtn { float: left; width: 17px; height: 17px; padding: 0px !important; border: 0px !important; outline: none !important; box-shadow: none !important; background: transparent !important; -webkit-transition: 0.2s; -moz-transition: 0.2s; -ms-transition: 0.2s; -o-transition: 0.2s; transition: 0.2s; } 
.actionbtn img { float: left; height: 100%; width: 100%; } 
.actionbtn.active img { transform: rotate(180deg); -webkit-transition: 0.2s; -moz-transition: 0.2s; -ms-transition: 0.2s; -o-transition: 0.2s; transition: 0.2s; } 
.panelContentwrapper .table tbody .actionbtn, .panelContentwrapper_new .table tbody .actionbtn { margin-right: 12px; } 
.panelContentwrapper .table tbody .actionbtn:last-child, .panelContentwrapper_new .table tbody .actionbtn:last-child { margin-right: 0px; } 
.table-bordered>:not(caption)>* { border-color: #dee2e6 !important; } 
.tablecollapsewraps .table { margin-bottom: 0px !important; } 
.datepickField .react-datepicker__close-icon { right: 29px; } 
.datepickField .react-datepicker__close-icon::after {color: red;content: "\F622";font-family: bootstrap-icons;font-size: 13px;background: transparent !important;} 
.react-datepicker .react-datepicker__month-select { height: auto !important; padding: 1px 5px; border-radius: 8px; width: 100px; } 
.react-datepicker .react-datepicker__year-select { height: auto !important; padding: 1px 7px; border-radius: 8px; width: 70px; } 
#mb-3 { margin-bottom: 7px !important; } 
.filtercollapsebtn { background-color: #fff; padding: 7px 9px; font-size: 15px; border-radius: 4px; border: 1px solid #dee2e6; } 
#stocklevelExportbtn { width: auto; margin: 0px 7px 0px; padding: 6px 7px !important; background-color: #158149 !important; } 
#stocklevelExportbtn img { width: 19px; line-height: normal; } 
#PurchaseOrderbtn { float: left;width: auto;margin: 0px;padding: 6px 15px !important;font-size: 16px;} 
#PurchaseOrderClearbtn { width: auto; margin: 0px; background-color: transparent !important; border-color: #DEE2E5 !important; padding: 6px 15px !important; font-size: 16px; } 
#PurchaseOrderClearbtnsetnew { width: auto; margin: 0px; background-color: transparent !important; border-color: #DEE2E5 !important; padding: 7.5px 11px !important; font-size: 16px; } 
#PurchaseOrderbtnnew { width: auto; margin: 0px; padding: 7.5px 11px !important; } 
#PurchaseOrderClearbtnnew { width: auto; margin: 0px; background-color: #0E0E30 !important; border-color: #0E0E30 !important; padding: 7.5px 9px !important; } 
#PurchaseOrderClearbtnnew i { margin: 0px !important; } 
#userLimitselect { width: 85px !important; } 
.notformsection ul {text-align: left;margin: 0px;padding: 10px 10px;background-color: #f2f2f2;border-radius: 10px;border: 1px solid #d9d9d1;} 
#print_btn_function { padding: 0px !important; height: 21px; } 
.notformsection ul li { font-size: 16px; padding-bottom: 9px; display: flex; align-items: center; justify-content: center; column-gap: 9px; } 
.notformsection ul li:last-child { padding-bottom: 0px; } 
.notformsection ul li span { float: left; width: 24px; height: 24px; background-color: #333; color: #fff; display: flex; align-items: center; text-align: center; justify-content: center; border-radius: 100%; font-size: 14px; } 
.QauntityManage { float: left; width: auto; display: flex; align-items: center; column-gap: 4px; background-color: #eee; border-radius: 7px; padding: 2px 5px; } 
.QauntityManage .btn { padding: 4px 5px; outline: none !important; box-shadow: none !important; border: 0px !important; font-size: 15px; } 
.QauntityManage h2 { font-size: 15px; line-height: normal; margin: 0px; } 
.QauntityManage .remove .bi {color: red;} 
.QauntityManage .add .bi {color: #10b068;} 
.GSTAmounttotal { float: left; width: 100%; padding-left: 17px !important; margin: 0px; padding: 8px 0px; border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; margin-bottom: 10px; } 
.GSTAmounttotal li { float: left; width: 100%; padding: 2px 0px; font-size: 14px; color: #333; } 
.GSTAmounttotal li label { font-weight: 500; float: left; } 
.FranchiseWallet { float: left; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 10px 0px; margin-bottom: 5px; font-size: 15px; } 
.FranchiseWallet .Franchise_name { padding: 2px 0px; font-size: 14px; color: #333; display: flex; column-gap: 10px; } 
.FranchiseWallet .Franchise_name label {font-weight: 500;} 
.FranchiseWallet .FranchiseBlance_name { font-size: 14px; color: #333; display: flex; column-gap: 10px; background-color: #f5f5f5; padding: 6px 15px; border-radius: 20px; } 
.FranchiseWallet .FranchiseBlance_name label {font-weight: 500;} 
.GSTAmounttotal textarea.form-control { border-radius: 4px; box-shadow: none !important; width: 300px; float: left; font-size: 13px; padding: 8px 10px; height: 95px; } 
.PurchaseOrderCheckbox .form-check { display: flex; align-items: center; column-gap: 8px; } 
.PurchaseOrderCheckbox .form-check-input[type=checkbox] { height: 17px; width: 17px; } 
.Purchase_Order_Mendetory .godown-select__placeholder::after { content: "*"; color: red; } 
.pdffileviewo { float: left; width: 100%; padding: 6px 23px 6px 14px; font-size: 14px; border: 1px solid #ddd; background-color: #f9f9f9; border-radius: 7px; position: relative; display: flex; column-gap: 8px; word-break: break-word; } 
.smallLabelData { float: left; width: 100%; padding: 8px 10px; border: 1px solid #DEE2E5; font-size: 13px; position: relative; height: 38px; cursor: no-drop; border-radius: 4px; } 
.smallLabelData small { float: left; width: auto; background-color: #fff; position: absolute; left: 3px; top: -8px; padding: 0px 8px; font-size: 11px !important; } 
.smallLabelData div { text-transform: capitalize;display: inline-block; width: 100%; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis; } 
.react-datepicker-popper { z-index: 9999999; } 
#backbtbackgt { background-color: transparent !important; border-color: #DEE2E5 !important; padding: 8px 25px !important; color: #000 !important; } 
.smallLabelData .clearbtn { position: absolute; right: -6px; top: -7px; line-height: normal; background-color: #fff; border-radius: 100%; cursor: pointer; } 
.smallLabelData .clearbtn i { color: red; } 
#FranchiseUserReport .userLimitselect { margin-right: 10px; } .notinternetFoundPage { float: left; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: rgba(255,255,255,0.5); backdrop-filter: blur(4px); display: flex; align-items: center; justify-content: center; z-index: 9999; } 
.notinternetFoundPage .connectwrapp p { margin-bottom: 25px; font-size: 17px; } 
.notinternetFoundPage .connectwrapp { float: left; width: 540px; padding: 35px; background-color: #fff; border: 1px solid #ddd; border-radius: 8px; } 
.notinternetFoundPage .connectwrapp h2 { font-weight: 600; margin: 0 0 12px; } 
.notinternetFoundPage .connectwrapp .btn { background-color: #004DAC !important; border-radius: 4px; border-color: #004DAC !important; padding: 9px 20px; font-size: 16px; color: #fff; } 
.notinternetFoundPage .connectwrapp .bi-wifi-off { font-size: 40px; color: red; } 
.formloginsetStaticData .form-label { margin: 0 0 5px !important; } 
.formloginsetStaticData .mb-3 { margin-bottom: 10px !important; } 
.qntyClass { border-radius: 0px; box-shadow: none; width: 110px !important; } 
.account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;color: #495057;margin: 0px!important;} 
.account-table> tr>td, .account-table> tr>th {border: 1px solid #000000;border-right: 1px solid #000000;} 
.account-table> tr:last-child>td, .account-table> tr:last-child>th{border-bottom: 1px solid #000000;} 
.account-table> tr:first-child>td, .account-table> tr:first-child>th{border-top: 1px solid #000000;} 
.account-table> tr>td, .account-table tr>td, .account-table tr>th{padding: 5px;} 
.account-table h5{font-size: 14px;} 
.account-table h2{font-size: 18px;} 
.account-table hr{ margin: 0 -1px;padding: 0px!important;} 
.account-table h5, .account-table h2, .account-table p{margin: 0px;padding: 0px!important;} 
.account-table .bt-0{border-top-width: 0px!important;} 
.account-table .bl-0{border-left-width: 0px!important;} 
.account-table .bb-0{border-bottom-width: 0px!important;} 
.account-table .br-0{border-right-width: 0px!important;} 
.account-table .logo .text-center{width: calc(100% - 360px);} 
.account-table .tax-invoice{ background: #004DAC; color: #fff; padding: 10px 26px; font-size: 20px; font-weight: bold; border-radius: 18px; display: inline-block; } 
.t-body-nowrap .carrier.min{min-width: 140px;} 
.t-body-nowrap .carrier{white-space: nowrap;min-width: 170px;} 
.table-common tr>th{font-size: 13px!important;} 
.table-common tr>td{font-size: 13px!important;} 
span.light-green{ background-color: #D0EAFF !important; color: #015BA5 !important; font-weight: 500; padding: 9px 22px !important;font-size: 13px !important; border-radius: 100px !important;font-weight: 500 !important;letter-spacing: 0.5px !important;} 
span.light-orange{ background-color: #ffc107 !important; color: white; font-weight: 500; padding: 9px 22px !important;font-size: 13px !important; border-radius: 100px !important;font-weight: 500 !important;letter-spacing: 0.5px !important;} 
span.light-red{ background-color: #FFD5D5 !important;color: #C70101 !important;font-weight: 500; padding: 9px 22px !important;font-size: 13px !important; border-radius: 100px !important;font-weight: 500 !important;letter-spacing: 0.5px !important;} 
span.light-grey{ background-color: #acadb1 !important; color: white; font-weight: 500; padding: 9px 22px !important;font-size: 13px !important; border-radius: 100px !important; font-weight: 500 !important;letter-spacing: 0.5px !important;} 
.btn.btn-icon{ height: 35px; width: 35px; align-items: center; justify-content: center; display: inline-flex; outline: 0px!important; border-color: transparent!important; box-shadow: none!important; } 
.btn.btn-icon::after{display: none;} .btn.btn-icon i{font-size: 19px;} 
.modal-invoice .btn.btn-icon{position: absolute;right: 50px;} 
.invoice_model{ -webkit-print-color-adjust: exact; print-color-adjust: exact; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -webkit-color-adjust: exact; color-adjust: exact; } 
.invoice-details-common img{object-fit: contain;} 
.createshortBox { padding: 0px !important; font-size: 27px !important; line-height: normal !important; outline: none !important; border: 0px !important; } 
.addBoxbtns { background-color: #E0EEFF !important;border-color: #E0EEFF !important; padding: 6.8px 15px !important; font-size: 15px !important; border-radius: 4px !important; } 
.deleteItemBtn { color: red; font-size: 15px; cursor: pointer;width: 20px; }
#addBoxbtns { margin: 0px; width: auto; } 
.selectBoxAnchor { float: right; font-size: 14px; text-decoration: none; color: #004dac !important; cursor: pointer; font-weight: 500; line-height: normal;} 
.createshortBox i { line-height: normal; color: #10b068; } @media print { .account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;} 
.account-table tr>td, .account-table tr>th {border: 1px solid #000000!important;} } 
.commonLoaderwaraplogin { float: left; width: 100%; height: 100%; position: fixed; left: 0; top: 0; display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.6); z-index: 999; color: #fff; column-gap: 10px; } 
.commonLoaderwarap { float: left; width: 100%; height: 100%; position: fixed; left: 0; top: 0; display: flex; align-items: center; justify-content: center; z-index: 99999;column-gap: 10px; background: rgb(30 30 30 / 50%);backdrop-filter: blur(4px);} 
.commonLoaderwarapBox {float: left;width: 250px;height: 180px;display: flex;align-items: center;justify-content: center;flex-direction: column;background-color: #fff;border: 1px solid #ddd;border-radius: 18px;overflow: hidden;}
.commonLoaderwarapBox img {width: 327px;border-radius: 16px;height: 99px;object-fit: cover;}
.commonLoaderwarapBox span {font-weight: 600;margin: 0;font-size: 15px;}
.serachBoxWIthNewMain .filedinfomsg { position: absolute; left: 10px; top: 11px; } 
.serachBoxWIthNewMain input { padding-left: 32px; } 
.replacebtn { color: #fff !important; padding: 6.4px 10px !important; margin: 0px !important; } 
.border-right { position: relative; } 
.border-right::after { content: ""; float: left; width: 1px; height: 57%; background-color: #ccc; position: absolute; right: 0; top: 0; } 
.errorDetailsModal .modal-body p { float: left; width: 100%; word-break: break-word; } 
.customerdetails { float: left; width: 100%; font-size: 14px; margin: 0 0 10px; } 
#submitframebtn { width: auto; padding: 6.9px 10px !important; margin: 0px !important; } 
.customerdetails label { float: left; width: auto; margin-right: 7px; font-weight: 500; } 
ul.fileuploadListy { float: left; width: 100%; margin: 0px; padding: 0px; margin-top: 10px; } 
ul.fileuploadListy li { float: left; width: 100%; list-style: none; } 
.fileuploadListy .uplaodedFilename { margin-bottom: 10px; } 
.fileuploadListytable { float: left; width: 100%; padding-left: 15px; margin: 0px; } 
.fileuploadListytable li { float: left; width: 100%; margin-bottom: 5px; } 
.UploadCmnBTN.UploadCmnAttchmentBTN .spinner-border { height: 20px; width: 20px; position: absolute; right: 10px; top: 6px; } 
.order_logs_box .completed-icon {float: left;width: auto;height: 100%;display: flex;align-items: center;}
.order_logs_box  .completed-icon::before, .order_logs_box  .completed-icon::after {content: "";border-left: 2px dashed #004DAC;height: 50%;margin-left: 9px;position: absolute;left: 16px;}
.order_logs_box  .completed-icon::before {top: -14px;} 
.order_logs_box  .completed-icon::after {bottom: -14px;} 
.completed {display: flex;align-items: center;background-color: #004DAC;border-radius: 7px;width: 30px;height: 30px;justify-content: center;z-index: 99;position: relative;margin: 0 auto 0px 11px;float: none;} 
.order_logs_wrapper .row:last-child .order_logs_box {margin-bottom: 0px;}
.order_logs_wrapper .row:last-child .order_logs_box .completed-icon::after {display: none;}
.order_logs_wrapper .row:first-child .order_logs_box .completed-icon::before {display: none;}
.add-icon-francise-brand{ margin-top: 29px; } 
.add-icon-francise-brand .AddItemBtn { color: white; font-size: 25px; cursor: pointer; } 
.permissionmodel .modal-dialog{ max-width: 1200px; } .child { font-size: 14px; } 
.formloginset .ToggleMainWrap .permission-btn, .permissionbtn{ background-color: #004dac !important; border-radius: 4px !important; padding: 7px 17px !important; font-size: 14px !important; color: #fff !important; border-color: #004dac !important; } 
#permissionbtn {background-color: #E0EEFF !important;border-color: #E0EEFF !important;color: #004dac !important;width: 100% !important;padding: 8px 20px !important;font-size: 16px !important;}
#permissionbtn .bi {font-size: 15px !important;}
#permissionbtn .spinner-border {border-width: 3px;}
.Rm-Quick-Login-disable { cursor: no-drop; } 
.permissiontablAdminTolls #productMastersRMSMWH.form-check-input:disabled, .permissiontableView #productMastersAfs.form-check-input:disabled , #after_sales_and_service_menu #productMasters.form-check-input:disabled { background: gray; cursor: no-drop; } 
.selectec_type_wraps { float: left; width: 100%; margin: 0 0 9px; font-size: 14px; } 
.selectec_type_wraps label { float: left; width: auto; margin-right: 8px; font-weight: 500; } 
.usertyperPermission { width: auto; float: right; margin-left: 10px; text-transform: capitalize; font-size: 15px; padding: 8px 20px; line-height: normal;background-color: #e0eeff !important;border-color: #e0eeff !important;color: #333 !important; border-radius: 18px; } 
#margintopRemove { margin-top: 0px !important; } 
.iframe_wrapper { float: left; width: 100%; height: calc(100vh - 145px); position: relative; overflow: auto; } 
.iframe_wrapper iframe { margin: 0; padding: 0; display: block; overflow: hidden; } 
.errorMessageIcon { color: red; } 
.kvyregisterdnoregs { float: right; font-size: 12px; margin-left: 20px; border-left: 1px solid #dddd; padding-left: 12px; } 
.kvyregisterdnoregs .form-check.form-switch { margin: 0px; min-height: auto; display: flex; align-items: center; column-gap: 8px; } 
.kvyregisterdnoregs .form-check.form-switch input {height: 14px;} 
.anchorDownoad { color: #0d6efd; text-decoration: underline; cursor: pointer; }
#btn-groups-main-id.btn-groups-main { display: flex; float: left; width: 100%; justify-content: space-between; align-items: center; row-gap: 11px; padding: 25px 1rem 20px !important; flex-direction: column; } 
#btn-groups-main-id.btn-groups-main .btn { padding: 9px 10px; font-size: 14px; width: 100%; border: 1px solid #cacaca; color: #0E0E30; position: relative; float: left; overflow: hidden; z-index: 1; border-radius: 4px; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s; } 
#btn-groups-main-id.btn-groups-main .btn::after { content: ""; background-color: #E0EEFF; float: left; width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: -9; transform: translateY(100%); -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s; } 
#btn-groups-main-id.btn-groups-main .btn:hover { border: 1px solid #E0EEFF; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s; } 
#btn-groups-main-id.btn-groups-main .btn:hover::after { transform: translateY(0%); -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s; } 
#btn-groups-main-id.btn-groups-main .btn::before { content: "\F18D"; font-family: 'bootstrap-icons'; font-size: 21px; position: absolute; left: -40px; top: 3px; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s; } 
#btn-groups-main-id.btn-groups-main .btn:hover::before { left: 10px; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s; } 
.commonModalStockInward .modal-dialog { max-width: 360px; } 
.Order-NotBilled-Row td, .Order-NotBilled-Row td:hover { background-color: #fff1d7 !important; background: #fff1d7 !important; } 
.Order-NotBilled-Row td .not_billed_orange, .Order-NotBilled-Row td:hover .not_billed_orange { background: #ffdd9d !important; color: #ae7100 !important; } 
.Order-NoActive-Row td .badge.bg-danger, .Order-NoActive-Row td:hover .badge.bg-danger { background-color: #f6d2d2 !important; color: #dc3545 !important; } 
.Order-NoActive-Row td, .Order-NoActive-Row td:hover { background-color: #fbebeb !important; background: #fbebeb !important; } 
.status_mark.yet_to_start { background-color: #cdebff !important; color: #005085 !important; } 
.status_mark.expired { color: #00AC20 !important; background-color: #00AC20 #d8f3dd !important; } 
.status_mark.expired { background-color: #f1f1f1 !important; color: #8D8D8D !important; } 
.status_mark.in_progress { color: #4d3900; background-color: #ffe188 !important; } 
.status_mark.is_closed { color: #222222; background-color: #dadada !important; } 
#exportbtn { padding: 6px 10px !important; color: #333 !important; } 
.FranchiseOrdersTablesDetails .modal-dialog{ max-width: 800px; } 
.Bike_Condition_iamges .AvtarMain { margin-right: 9px; cursor: pointer; } 
.FranchiseOrdersTablesDetailsTableERP .modal-dialog{ max-width: 900px; } 
.FranchiseOrdersTablesDetailsTableGeneralERP .modal-dialog{ max-width: 1000px; } 
.Main_section_warehouse .exportbtn { margin-left: 10px; } 
.settting_Staus_wrapper { float: left; width: 100%; position: relative; } 
.settting_Staus input { border-radius: 0px; height: 40px; box-shadow: none !important; font-size: 13px; } 
.settting_Staus_wrapper .settting_Staus { float: left; width: 170px; display: flex; align-items: center; margin-right: 25px; border-radius: 4px; overflow: hidden; } 
.settting_Staus.settting_Staus_score input { text-align: center; padding: 10px 12px 10px 12px !important; } 
.settting_Staus.settting_Staus_score .badge { width: 100px !important; } 
.settting_Staus_wrapper .settting_Staus .badge { float: left; width: 70px; height: 40px; border-radius: 0px !important; display: flex; align-items: center; justify-content: center; font-size: 13px !important; } 
.commonDeleteModals p { font-size: 17px; } 
.erperro_mesage { float: left; width: 40%; padding-left: 15px; margin: 10px 0 0; } 
.erperro_mesage li { float: left; width: 100%; word-wrap: break-word; white-space: normal; margin-bottom: 3px; } 
.is_dispached.badge { background-color: #ffcfe0 !important; color: #4d3900; } 
.settting_Staus_wrapper .settting_Staus .statusPercentafe { float: left; width: 100%; display: flex; align-items: center; justify-content: center; position: relative; } 
.settting_Staus_wrapper .settting_Staus .statusPercentafe input { height: 40px; border-radius: 0px 4px 4px 0px; box-shadow: none !important; font-size: 13px; padding: 10px 40px 10px 9px; } 
.settting_Staus_wrapper .settting_Staus .statusPercentafe span { float: right; width: auto; position: absolute; right: 0; height: 100%; padding: 10px 10px; display: flex; align-items: center; line-height: normal; } 
.settting_Staus_wrapper .settting_Staus.success .statusPercentafe input { border-color: #eaf7ec !important; } 
.settting_Staus_wrapper .settting_Staus.success .statusPercentafe span { background-color: #D0EAFF !important; color: #015BA5 !important; } 
.settting_Staus_wrapper .settting_Staus.danger .statusPercentafe input { border-color: #fbebeb  !important; } 
.settting_Staus_wrapper .settting_Staus.danger .statusPercentafe span { background-color: #fbebeb ; color: #dc3545 !important; } 
.save_percentage {background-color: #e0eeff !important;border-color: #e0eeff !important;border-radius: 4px !important;color: #333 !important;overflow: hidden;position: relative;z-index: 9;padding: 7px 20px !important;font-size: 16px !important;} 
.point_sections_headers { float: left; width: 100%; margin-bottom: 14px; text-align: right; } 
.Bike_Condition_iamges { float: left; width: 100%; } 
.Bike_Condition_iamges ul { margin: 0px; padding-left: 15px; } 
.Bike_Condition_files div { float: left; width: auto; cursor: pointer; } 
.Bike_Condition_iamges ul li { float: left; width: 100%; position: relative; } 
.Bike_Condition_iamges ul li .youtube_wrapper { float: left; width: auto; position: relative; cursor: pointer; } 
/* preview image modal css */ 

.Image_preview_wrapper { float: left; width: 100%; height: calc(100dvh); position: fixed; margin: 0 auto; text-align: center; padding: 13px 25px 25px 25px; z-index: 9999; overflow: auto; left: 0; right: 0; top: 0; color: #fff; } 
.preivew_image { float: left; width: 100%; height: 100%; } 
.preivew_image img { height: 100%; } 
.preivew_image video { height: 100%; } 
.Image_preview_wrapper h4 { float: left; width: 100%; margin: 0 0 20px; } 
.Image_preview_wrapper .bi-download { float: right; cursor: pointer; color: #fff; font-size: 34px; position: absolute; right: 80px; top: 2px; } 
.Image_preview_wrapper .bi-x-lg { float: right; cursor: pointer; color: #fff; font-size: 38px; position: absolute; right: 20px; top: 0px; } 
.background_wrapper { float: left; width: 100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 99; background: rgba(0, 0, 0, 0.5); } 
.Image_preview_wrapper_header { float: left; width: 100%; text-align: center; position: relative; } 
.Bike_Condition_iamges .AvtarMain img { height: 60px; } 
.edittable.edittableretry { font-size: 15px !important; display: flex; column-gap: 7px; } 
.edittable.edittableretry:hover { color: #004DAC !important; text-decoration: underline; } 
.MainHeader_Tab .sendemailstocks { float: right; width: auto; position: absolute; right: 0; top: -5px; font-size: 14px; padding: 6px 10px; line-height: normal; background-color: #004DAC !important;border-color: #004DAC !important;color: #fff !important;border-radius: 4px;} 
.commonModalStockInwardEmails .form-control { border-radius: 4px !important; box-shadow: none !important; outline: none !important; border-color: #dee2e6 !important; font-size: 13px; height: 38px; } 
.commonModalStockInwardEmails .images_labels_wrapper { padding: 0px; margin: 0px; } 
.commonModalStockInwardEmails .images_labels_wrapper li { list-style: none; background-color: #eee; padding: 7px 30px 7px 13px; font-size: 14px; border-radius: 30px; width: auto; } 
.commonModalStockInwardEmails .images_labels_wrapper li i { position: absolute; right: 9px; top: 7px; color: red; cursor: pointer; } 
.type_checkbox_wrapper { float: left; width: 100%; display: flex; align-items: center; } 
.type_checkbox_wrapper .form-label { display: flex; align-items: center; flex: auto; direction: rtl; justify-content: flex-end; column-gap: 7px; } 
.commonModalStockInwardEmails .permissionbtn .spinner-border { height: 15px; width: 15px; } 
.commonModalStockInwardEmails .btn_add {border-radius: 4px !important;background: transparent;}
.error_medotory .bi-x-circle-fill { font-size: 11px; } 
.add_images_wraper .btn_add {background-color: #004dac !important;border-color: #004dac !important;color: #fff !important;position: absolute;top: 0;right: 0;height: 100%;line-height: normal;padding: 0px 7px;} 
.add_images_wraper .btn_add .bi {font-size: 24px;line-height: normal;}
.images_labels_wrapper_listss li { float: left; width: 100%; margin-bottom: 10px; font-size: 15px; word-break: break-all; padding-right: 15px; } 
.images_labels_wrapper_listss li strong { float: left; width: 100%; font-size: 15px; } 
.images_labels_wrapper_listss li .bi-trash3-fill { position: absolute; right: 0; top: 0; } 
.images_labels_wrapper_listss { padding-left: 20px; } 
.add_images_wraper { float: left; width: 100%; position: relative; border-radius: 4px;overflow: hidden;} 
.rangerefrence_wrapper { float: left; width: 100%; display: flex; align-items: center; justify-content: center; column-gap: 10px; } 
.uploade_images_wrapper { float: left; width: 100%; display: flex; align-items: center; justify-content: center; column-gap: 10px; margin: 0 0 10px; } 
.uploade_images_wrapper .image_preview_wraps { float: left; width: 100%; border: 1px solid #ddd; height: 100px; border-radius: 10px; position: relative; } 
.downloadbtn#downloadbtn { font-size: 19px; padding: 2px 7px; margin: 0 7px 0 0px; line-height: normal; background-color: #333 !important; border-color: #333 !important; color: #fff !important; border-radius: 0px; width: auto !important; } 
.order_warning_error { float: left; width: 100%; position: absolute; left: 0; top: 0; height: 100%; background-color: #fff; z-index: 99; display: flex; align-items: center; justify-content: center; text-align: center; font-size: 17px; flex-direction: column; row-gap: 10px; } 
.order_warning_error .bi-ban { font-size: 40px; color: red; } 
.erp_outstanding_main_section .date_time_stamp { right: 200px; } 
.uploade_images_wrapper .image_preview_wraps img { float: left; width: 100%; height: 100%; object-fit: cover; border-radius: 10px; } 
.uploade_images_wrapper .wrapps_upload_img .image_preview_wraps .bi-trash3-fill { position: absolute; bottom: 5px; right: 5px; z-index: 99; font-size: 13px; color: red; cursor: pointer; } 
.uploade_images_wrapper_list { padding: 0px !important; } 
.uploade_images_wrapper_list li { float: left; width: auto; list-style: none; margin-right: 10px; } 
.uploade_images_wrapper_list li .wrapps_upload_img { float: left; width: 90px; text-align: center; } 
.uploade_images_wrapper_list li .wrapps_upload_img img { width: 100%; height: 74px; object-fit: cover; object-position: center; } 
.uploade_images_wrapper_list li .wrapps_upload_img .image_preview_wraps { border-radius: 0.375rem; border: 1px solid #eee; overflow: hidden; } 
.uploade_images_wrapper .wrapps_upload_img { float: left; width: 33.33%; } 
.uploade_images_wrapper .wrapps_upload_img span { float: left; width: 100%; font-size: 13px; font-weight: 500; text-align: left; margin: 0 0 5px; display: flex; justify-content: space-between; } 
.uploade_images_wrapper .wrapps_upload_img span .delete_image_range { width: 16px; cursor: pointer; } 
.send_btn { padding: 7px 10px !important; font-size: 13px !important; background-color: #E0EEFF !important; color: #004DAC !important; border-radius: 4px !important; position: relative; overflow: hidden; border: 0px !important; -webkit-transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -ms-transition: 0.5s; transition: 0.5s; } 
.donwload_btn { padding: 7px 10px !important; font-size: 13px !important; background-color: #004DAC !important; color: #fff !important; border-radius: 4px !important; position: relative; overflow: hidden; border: 0px !important; -webkit-transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -ms-transition: 0.5s; transition: 0.5s; } 
.donwload_btn .download_progress_bra { float: left; width: 100%; height: 100%; background-color: #0E0E30; position: absolute; left: 0; top: 0; display: flex; align-items: center; justify-content: center; column-gap: 8px; padding: 8px; -webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s; } 
.donwload_btn.active { opacity: 1 !important; } 
.filter_stock_inward { float: left; width: 100%; } 
.filter_stock_inward.active { width: auto; position: absolute; right: 11px; top: 67px; } 
.active_user td, .active_user:hover { background-color: #F8F9FD !important; font-style: italic; font-weight: 500; } 
.active_user td .btn.edittable {display: none;}
.order_type_select { float: left; width: 100%; position: relative; } 
.order_type_select small { font-size: 11px; float: left; width: auto; position: absolute; left: 6px; top: -5px; background-color: #fff; padding: 0px 4px; } 
.order_type_select select { padding: 6px 12px 2px 9px !important; border-radius: 4px; font-size: 12px; } 
.error_note_bundle_wrapper { float: left; width: 80%; background-color: #fbebeb; border-left: 3px solid #dc3545; padding: 15px 15px; display: flex; align-items: center !important; column-gap: 9px; border-radius: 8px; } 
.error_note_bundle { font-weight: 400; font-size: 14px; margin: 0px; float: left; width: 90%; line-height: 20px; color: #dc3545; display: flex; align-items: center; column-gap: 8px; } 
.error_note_bundle .bi { font-size: 16px; } .error_note_bundle_wrapper .Resetbtnbundle { border-radius: 6px !important; } 
#Resetbtnbundle { margin-top: 0px !important; } 
.Courier_wrapper_image img { width: 175px; margin: 15px auto 20px; } 
.Courier_wrapper_image p { margin: 0px; font-size: 16px; line-height: 22px; } 
.Courier_wrapper_modal .modal-dialog { max-width: 410px; } 
#Courier_wrapper_modal .modal-content { border-radius: 30px !important; } 
#Courier_wrapper_modal .modal-content .modal-header .modal-title { font-size: 21px; text-align: center; } 
.Courier_wrapper_modal .modal-footer { justify-content: center; } 
#Courier_wrapper_modal .modal-footer .btn { border-radius: 30px !important; display: flex; align-items: center; column-gap: 4px; font-size: 15px; } 
#Courier_wrapper_modal .modal-footer .btn.addbtncmn { background-color: #ff0000 !important; border-color: #ff0000 !important; } 
#Courier_wrapper_modal .modal-footer .btn.addbtncmn img { width: 17px; } 
.bundle_order_active:hover, .bundle_order_active td { background-color: rgba(255, 165, 0, 0.2) !important; } 
.is_odi_order_active td { background-color: rgb(255 177 177 / 32%) !important; } 
.is_courier_pin_code_order_active td { background-color: #f3b70285 !important; } 
.order_bunddle_seelct { margin-right: 10px; } 
.order_bunddle_seelct .dropdown .btn { height: 36px !important; font-size: 13px; border: 0px !important; border-radius: 4px; box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important; background: transparent !important; color: #000 !important; } 
.order_bunddle_seelct .dropdown .btn::before { content: ""; float: left; width: 9px; height: 9px; border-radius: 100%; margin: 7px 6px 0px 0px; display: none; } 
.order_bunddle_seelct .dropdown.order_bunlde_active_Bundle_orders #dropdown-basic-button { background-color: rgba(255, 165, 0, 0.2) !important; border: 0px !important; } 
.order_bunddle_seelct .dropdown.order_bunlde_active_Bundle_orders .btn::before { background-color: rgba(255, 165, 0, 0.2); border: 0px !important; } 
.order_bunddle_seelct .dropdown.order_bunlde_active_All_Orders .btn::before { background-color: #eee; border: 0px !important; } 
.order_bunddle_seelct .dropdown .dropdown-menu a.All_Orders span { border: 1px solid #ddd !important; } 
.order_bunddle_seelct .dropdown .dropdown-menu a.Bundle_orders span { border: 1px solid orange !important; } 
.ScrollbarsSidebar .table textarea.form-control { box-shadow: none !important; border-color: #dee2e6 !important; border-radius: 4px !important; font-size: 13px; padding: 6px 8px !important; } 
.ScrollbarsSidebar .table .exportbtn {border-radius: 4px !important;} 
.order_bunddle_seelct .dropdown .dropdown-menu { border-radius: 0px; border-color: #ddd; padding: 0px; min-width: 170px; } 
.clearall_permissions_btn { position: absolute; right: 58px; top: 18px; border-radius: 30px !important; font-size: 14px !important; } 
.order_bunddle_seelct .dropdown .dropdown-menu a { font-size: 13px; padding: 10px 14px; line-height: normal; } 
.order_bunddle_seelct .dropdown .dropdown-menu a span { float: left; width: 9px; height: 9px; border-radius: 100%; margin: 5px 6px 0px 0px; }
.order_bunddle_seelct .dropdown .dropdown-menu a:hover { background-color: #f8f8f8 !important; color: #333 !important; } 
.order_ODA_bunddle_seelct .dropdown .btn::before { display: block !important; } 
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_All_Orders .btn::before, .order_ODA_bunddle_seelct .dropdown.ODA_order_active_Normal_Pincode .btn::before { background-color: #eee; border: 1px solid #ddd; } 
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_ODA_Pincode .btn::before, .order_ODA_bunddle_seelct .dropdown.ODA_order_active_ODA_Pincode .btn { background-color: rgba(245, 133, 133, 0.32) !important; border: 1px solid rgba(245, 133, 133, 0.32) !important; } 
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_Serviceability_missing .btn::before, .order_ODA_bunddle_seelct .dropdown.ODA_order_active_Serviceability_missing .btn { background-color: rgba(243, 183, 2, 0.52) !important; border: 1px solid rgba(243, 183, 2, 0.52) !important; } 
.date_time_stamp { float: left; width: auto; position: absolute; right: 201px; top: 1px; font-size: 14px; background-color: #eee; padding: 8px 14px 8px 14px; border-radius: 30px; } 
.date_time_stamp label { font-weight: 500; margin: 0 8px 0px 0px; } 
.outstanding_filters_datetime { float: left; width: 100%; position: relative; } 
.reassign_info { float: left; width: auto; font-size: 14px; background-color: #f2f2f2; padding: 10px 15px; border-radius: 10px; } 
.reassign_info .bi-info-circle-fill { font-size: 12px; } 
.old_warehouse_details { float: left; width: 100%; padding: 10px 10px; background-color: #f5f3f3; font-size: 13px; border: 1px solid #ddd; } 
.reassign_warehouse_wrapper { float: left; width: 100%; border: 1px solid #ddd; padding: 12px 11px 17px; margin: 0 0 15px; position: relative; border-radius: 8px; } 
.reassign_warehouse_wrapper .border_right { float: left; width: 48px; height: 50%; border: 5px solid #004DAC; position: absolute; right: -49px; top: 58px; border-left: 0px; border-radius: 0px 15px 15px 0px; } 
.reassign_warehouse_wrapper .border_right i { float: left; width: auto; position: absolute; left: -18px; bottom: -25px; color: #004DAC; font-size: 30px; } 
.reassign_warehouse_wrapper .col-md-12:last-child .mb-3 { margin-bottom: 0px !important; } 
.stocklevel_so_approval_modal .modal-dialog { max-width: 680px; } 
.UploadCmnBTNCommon { position: relative; } 
.UploadCmnBTNCommon .spinner-border { float: right; height: 20px; width: 20px; position: absolute; right: 10px; } 
.commonModalAttachRemarks .modal-dialog { max-width: 430px; } 
.add_remark { padding: 0px; border: 0px !important; box-shadow: none !important; } 
.warehousePriorityCl { background-color: #CCFFCC !important; } 
.warehousePrioritydanger { background-color: #FFCCCC !important; } 
#stock_level_so_approval tr { background: transparent !important; } 
.loader_main_button { float: left; width: auto; margin: 0px 0 0 14px !important; display: flex; align-items: center; column-gap: 10px; } 
.loader_main_button .loader_courier .spinner-border { width: 25px; height: 25px; } 
.load_more {font-size: 13px !important;background-color: #0E0E30 !important;color: #fff !important;padding: 8.3px 11px !important;border-radius: 4px !important;} 
.Check_Serviceable_tableView .table { margin-bottom: 0px; } 
.Check_serviceability_btn {font-size: 13px !important;background-color: #004dac !important;color: #fff !important;padding: 8.3px 11px !important;border-radius: 4px !important;float: right;} 
.Check_serviceability_btn:hover { background-color: #0E0E30 !important; border-color: #0E0E30 !important; } 
.order_bunddle_checkboxes { float: left; width: auto; margin: 6px 12px 0px 0px; } 
.order_bunddle_checkboxes .form-label { margin-bottom: 0px; display: flex; align-items: center; column-gap: 5px; font-size: 15px; } 
.order_bunddle_checkboxes .form-label div { width: auto; float: left; } 
.order_bunddle_checkboxes .form-label div input { border: 1px solid #dee2e6 !important; } 
.search_Navigations_wrapper .btn { padding: 0px; background-color: transparent !important; border: 0px !important; box-shadow: none !important; width: 100% !important; } 
.search_Navigations_wrapper .btn::after { display: none; } 
.search_Navigations_wrapper .btn .bi-x-circle-fill { position: absolute; cursor: pointer; right: 42px; top: 8px; z-index: 9; font-size: 12px; color: #dc3545; } 
.search_Navigations_wrapper .btn input { box-shadow: none !important; border-radius: 4px; padding: 7px 50px 7px 40px; font-size: 12px; height: 34px; background-image: url("../img/search_menu_icons.svg"); background-repeat: no-repeat; background-position: 96% center; background-size: 17px; cursor: pointer; border-color: #cacaca; background-color: #fff; } 
.search_Navigations_wrapper .btn input::placeholder { color: #A0AEC0; } 
.search_Navigations_wrapper .dropdown { box-shadow: none !important; width: 305px; } 
.search_Navigations_wrapper .dropdown .search_icons { position: absolute; left: 12px; z-index: 99; color: #333; top: 9px; width: 16px; } 
.search_Navigations_wrapper .dropdown .dropdown-menu { padding: 5px 0px; overflow: auto; max-height: 300px !important; border-color: #ddd; } 
.searchHeading_links { padding: 6px 9px 6px 52px !important; column-gap: 5px !important; } 
.searchHeading_links img.search_icons { margin-left: 6px; width: 14px !important; } 
.search_Navigations_wrapper .dropdown .dropdown-item { float: left; width: 100%; position: relative; font-size: 13px; padding: 6px 9px 6px 9px; display: flex; align-items: center; column-gap: 9px; } 
.module_not_found { padding: 7px 7px 7px 7px !important; flex-direction: column; font-size: 14px !important; font-weight: 400 !important; row-gap: 7px; } 
.module_not_found .bi-ban {color: red;font-size: 20px;}
.search_Navigations_wrapper .dropdown .dropdown-item:active, .search_Navigations_wrapper .dropdown .dropdown-item:hover, .search_Navigations_wrapper .dropdown .dropdown-item:focus { background-color:#f8f8f8 !important; color: #333 !important; } 
.search_Navigations_wrapper .dropdown .dropdown-item:focus-visible { box-shadow: none !important; border: 0px; outline: none; } 
.search_Navigations_wrapper .dropdown .dropdown-item .searchNavigationIcons { float: left; width: 40px; height: 33px; display: flex; align-items: center; justify-content: center; background-color: #F8F9FA; border-radius: 4px; }
.search_Navigations_wrapper .dropdown .dropdown-item .searchNavigationIcons img {width: 14px;}
.search_Navigations_wrapper .dropdown .dropdown-item .searchNavigationIcons.logout_design {background-color: #E0EEFF !important;}
.search_Navigations_wrapper .dropdown .dropdown-item .searchNavigationIcons .bi-exclamation-triangle-fill {color: #004dac;}
.search_Navigations_wrapper .dropdown .dropdown-item .searchNavigationIcons img.dishboard_icons {filter: invert(72%) sepia(12%) saturate(14%) hue-rotate(1deg) brightness(86%) contrast(83%);}
.search_Navigations_wrapper .dropdown .dropdown-item:active .searchNavigationIcons, .search_Navigations_wrapper .dropdown .dropdown-item:hover .searchNavigationIcons,.search_Navigations_wrapper .dropdown .dropdown-item:focus .searchNavigationIcons {background-color: #E0EEFF;}
.search_Navigations_wrapper .dropdown .dropdown-item:active .searchNavigationIcons img, .search_Navigations_wrapper .dropdown .dropdown-item:hover .searchNavigationIcons img,.search_Navigations_wrapper .dropdown .dropdown-item:focus .searchNavigationIcons img {filter: invert(13%) sepia(80%) saturate(7263%) hue-rotate(201deg) brightness(86%) contrast(114%);}
.search_Navigations_wrapper .dropdown div { float: left; width: 100%; font-size: 13px !important; line-height: normal; border-radius: 0px; } 
.search_Navigations_wrapper .dropdown small { float: left; width: 100%; font-size: 9px; line-height: normal; text-align: left; color: #a4a4a4; position: relative; font-weight: 400; letter-spacing: 1px; text-transform: uppercase; } 
.search_Navigations_wrapper .dropdown small::after { content: ""; } 
.search_Navigations_wrapper { float: right; width: auto; margin: 0 20px 0px 0px; position: relative; } 
.sales_person_uplaod_downlo_wrapper { float: left; width: auto; margin-right: 9px; } 
.import_monthly_target { font-size: 13px !important; border-radius: 4px !important; padding: 7.5px 15px 7.5px 33px !important; background-color: #E0EEFF !important; border: 0px !important; position: relative; overflow: hidden; z-index: 9; -webkit-transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; -ms-transition: 0.5s; transition: 0.5s; } 
.import_monthly_target i { font-size: 16px; position: absolute; left: 12px; top: 6px; } 
.import_monthly_target:hover .bi-arrow-repeat {-moz-animation: spin 2s linear infinite;-o-animation: spin 2s linear infinite;-webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite; } 
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}  
.import_monthly_target input[type="file"] { position: absolute; left: 0; top: 0; visibility: hidden; } 
.import_monthly_target .spinner-border { height: 17px; width: 17px; float: left; margin: 2px 0 0 0;position: absolute; left: 12px; top: 7px;border-width: 2px;} 
.sales_person_uplaod_downlo_wrapper .btn.sales_download_file { background-color: #0E0E30 !important; color: #fff !important; border-radius: 0px 20px 20px 0px; padding: 7px 11px 7px 25px; margin: 0 0 0 -19px; } 
.splits_wrapper_list { float: left; width: 30%; position: fixed; right: 10px; top: 68px; z-index: 999; background-color: #fff; border: 1px solid #0a58ca; border-radius: 6px; overflow: hidden; } 
.splits_wrapper_list.react-draggable-dragging { cursor: grabbing !important; } 
.splits_wrapper_list.react-draggable-dragged { cursor: grab; } 
.splits_wrapper_list .splits_wrapper_header { float: left; width: 100%; padding: 10px 55px 10px 10px; border-bottom: 1px solid #0a58ca; background-color: #e8f1ff !important; color: #0a58ca !important; } 
.splits_wrapper_list .splits_wrapper_header h4 { margin: 0px; font-size: 15px; } 
.splits_wrapper_list .splits_wrapper_table { float: left; width: 100%; padding: 10px; max-height: calc(100vh - 120px); } 
.splits_wrapper_list.error { border-color: #dc3545 !important; } 
.splits_wrapper_list.error .splits_wrapper_header { background-color: #fbebeb !important; color: #dc3545 !important; border-color: #dc3545 !important; } 
.btn-view { padding: 9px 15px !important; line-height: normal !important; font-size: 14px !important; border: 1px solid #ddd !important; margin: 4px 0 0 !important; } 
.splits_wrapper_header .modal_setting { float: right; width: auto; column-gap: 6px; display: flex; font-size: 17px; position: absolute; right: 10px; top: 7px; } 
.splits_wrapper_header .modal_setting .bi { cursor: pointer; } 
.splits_wrapper_table .table thead tr th, .splits_wrapper_table .table tbody tr td { font-size: 13px; padding: 7px 10px; } 
.search_Navigations_wrapper .dropdown .dropdown-item strong { font-weight: 600 !important; color: #000 !important; }

/* Hide scrollbar when not needed */
.table-responsive::-webkit-scrollbar, .ScrollbarsSidebar .view::-webkit-scrollbar { display: none;} 
.table-responsive, .ScrollbarsSidebar .view {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */} 
.table-responsive { scrollbar-width: none;-ms-overflow-style: none;} 
.orderCncelModalCMN .modal-title .bi { color: #10b068; } 
.verify_btn { width: auto !important; padding: 7.5px 14px !important; font-size: 14px !important; } 
#reset_btn { background-color: transparent !important;border-color: #DEE2E5 !important; width: auto; padding: 7.5px 14px !important; margin-left: 10px; font-size: 14px; } 
.verifySuccessErrorModal .modal-dialog { max-width: 410px; } 
.verifySuccessErrorModal .modal-body {text-align: center;} 
.verifySuccessErrorModal .modal-body .bi-check2-circle { float: left; width: 100%; margin: 0 0 11px; color: #00AC20; font-size: 85px; line-height: 85px; } 
.verifySuccessErrorModal .modal-body h5 { float: left; width: 100%; font-size: 20px; margin: 0 0 10px; } 
.verifySuccessErrorModal .modal-body p { line-height: normal; font-size: 15px; margin: 0px; } 
.verifySuccessErrorModal .modal-body .verify_deta { float: left; width: 100%; text-align: left; font-size: 13px; border-top: 1px solid #ddd; } 
.verifySuccessErrorModal .modal-body .verify_deta ul { float: none; width: 310px; margin: 10px auto 0; padding: 0px; display: table; } 
.verifySuccessErrorModal .modal-body .verify_deta ul li { float: left; width: 100%; list-style: none; margin: 0 0 3px; } 
.verifySuccessErrorModal .modal-body .verify_deta ul li label { float: left; width: 140px; font-weight: 500; } 
.verifySuccessErrorModal .modal-footer { padding: 5px 10px; text-align: right; display: inline-block; } 
.proceed_btn { background-color: #E0EEFF !important;border-color: #E0EEFF !important;color: #004dac !important; padding: 9px 18px !important; font-size: 15px !important; border-radius: 0px !important; position: relative; } 
.verifySuccessErrorModal .modal-header h4{ font-size: 18px; margin: 0px; } 
.verifySuccessErrorModal .btn-close { box-shadow: none !important; outline: none !important; position: absolute; top: 11px; right: 11px; cursor: pointer; z-index: 99; } 
.verifySpan { float: right; width: auto; background-color: #00ac3e; color: #fff; border-radius: 30px; padding: 3px 9px 3px 4px; font-size: 12px; margin: -1px 0 0px 7px; } 
.verifySpan.verifySpanerror { background-color: #f60000 !important; } 
.banks_details_list { float: left; width: 100%; margin: 0px; padding: 0px 18px; } 
.banks_details_list ul { text-align: left; padding: 0px; font-size: 14px; float: left; width: 100%; margin: 0 auto 0; display: table; } 
.banks_details_list li label { font-weight: 500; } 
.bank_details_alerts { font-size: 14px; padding: 10px 13px !important; } 
.createScheme_wrapper .react-datepicker-popper { z-index: 99; } 
.addRangebtn { padding: 7.5px 10px !important; font-size: 14px !important; } 
.datepickField.datepickFieldSchome input { background-position: 95% center; } 
.incentive_slab_Main_section .card-header-New .card-header-right .userLimitselect { display: none; } 
.otp_wrappper { float: none; width: 75%; display: flex; justify-content: center; column-gap: 8px; margin: 0 auto 10px; }
.otp_wrappper input { text-align: center; } 
.loader_wrapper { float: right; width: auto; margin: 7px 9px 0px; } 
.loader_wrapper .spinner-border { height: 24px; width: 24px; border-width: 3px; } 
.ticktes_history_details_modal .modal_logs_headers span { float: left; width: 100%; font-size: 15px; line-height: normal; font-weight: 400; display: inline-block; } 
.ticktes_history_details_modal .modal-dialog { max-width: 380px; } 
.ticktes_history_details_modal ul { padding-left: 7px; margin: 0px } 
.ticktes_history_details_modal ul li { float: left; width: 100%; color: #606060; list-style: none; position: relative; padding-left: 35px; margin-bottom: 12px; } 
.ticktes_history_details_modal ul li::before { content: "\F287"; font-family: 'bootstrap-icons'; color: #606060; position: absolute; left: 0px; font-size: 14px; top: 3px; } 
.ticktes_history_details_modal ul li::after { content: ""; float: left; width: 2px; height: 100%; background-color: #606060; position: absolute; left: 6px; bottom: -20px; } 
.ticktes_history_details_modal ul li:last-child::after { display: none; } 
.ticktes_history_details_modal ul li h5 { font-size: 17px; margin: 0 0 1px; line-height: normal; } 
.ticktes_history_details_modal ul li span { font-size: 14px; } 
.fba_order_logs_Main_section { float: left; width: 100%; position: relative !important; } 
.fba_order_logs_Main_section .sales_person_uplaod_downlo_wrapper { position: absolute; left: 29%; top: 0; } 
.fba_order_logs_Main_section .sales_person_uplaod_downlo_wrapper .import_monthly_target { padding: 9px 17px !important; font-size: 13px !important; } 
.MainHeader.AfterSalesHeaderSteps { display: flex; justify-content: space-between; } 
.MainHeader.AfterSalesHeaderSteps h4 { float: left; width: auto; margin: 0px; font-size: 15px; line-height: normal; } 
.MainHeader.AfterSalesHeaderSteps .bi-backspace-reverse-fill { position: relative; top: 1px; } 
#confirm_order_btn { width: auto; margin: 0px !important; } 
.godown-select__menu-portal { z-index: 99 !important; } 
.FranchiseWallet.sales_order_tab_heading { padding: 0px; border: 0px; position: absolute; right: 0; width: auto; } 
.informative_text { line-height: 16px; display: flex; float: left; width: 100%; font-size: 13px; column-gap: 5px; margin: 2px 0 0; } 
.informative_text .bi { font-size: 11px; line-height: normal; float: left; width: auto; margin: 2px 0 0 0; } 
.warranty_order_cols { float: left; width: 100%; margin: 6px 0 0; } 
.warranty_order_cols .form-label { margin: 0px; width: auto; }
.warranty_order_cols .form-label div {display: flex;float: left;width: auto;margin-right: 7px;}
.warranty_order_cols .form-label div .form-check-input {width: 15px;height: 16px !important;}
#createSpares {margin: 0px !important;padding: 7px 20px !important;width: auto;font-size: 15px;}
#createSparesClear {margin: 0px !important;padding: 7px 20px !important;width: auto;font-size: 15px;background-color: transparent !important;border-color: #DEE2E5 !important;margin-left: 10px !important;}
.bottom_button_design {float: left;width: 100%;text-align: center;}
.Compatible_table tbody tr:last-child td {border-bottom: 0px !important;}
.spares_allready_label {float: left;width: 100%;font-size: 17px;color: red;border-bottom: 1px solid #ddd;padding-bottom: 9px;margin: 0 0 12px;}
#delete_all {background: transparent !important;color: red;border-color: red !important;}
.error_spare_item {background-color: #ffc3c3 !important;}
.statusof_spare_Items {float: left;width: auto;padding: 0px;}
.statusof_spare_Items li {float: left;width: auto;list-style: none;display: flex;align-items: center;justify-content: flex-start;column-gap: 7px;font-weight: 500;font-size: 14px;margin-right: 25px;}
.statusof_spare_Items li:last-child {margin-right: 0px;}
.statusof_spare_Items span{float: left;width: 18px;height: 18px;border-radius: 4px;}
.statusof_spare_Items .error {background-color: #ffc3c3;border: 1px solid #ffc3c3;}
.statusof_spare_Items .success {background-color: #fff;border: 1px solid #ddd;}
.labelAddOnFields {position: relative;}
.labelAddOnFields small {font-size: 11px;padding: 0px 5px;background-color: #fff;position: absolute;left: 18px;top: -6px;}
.view_more_btn {border: 1px solid #dee2e6 !important;background: transparent;font-size: 14px !important;color: #0E0E30 !important;padding: 4px 10px !important;}
.view_more_btn i {line-height: normal;font-size: 16px;margin-right: 4px;}
.faq_wrapper {float: left;width: 100%;text-align: left;font-size: 17px;}
.faq_wrapper label {font-weight: 500;margin-bottom: 7px;line-height: normal;}
.faq_wrapper p {line-height: 22px;font-size: 16px;margin-bottom: 0px !important;}

/* privacy policy page css */
.privacy_policy_wrapper {float: left;width: 100%;overflow: hidden;box-sizing: border-box;}
.privacy_policy_wrapper .heaing_main {float: left;width: 100%;display: flex;align-items: center;justify-content: space-between;padding: 30px 40px;box-sizing: border-box;border-bottom: 1px solid #ddd;margin: 0 0 32px;}
.privacy_policy_wrapper .heaing_main img {width: 100px;}
.privacy_policy_wrapper .heaing_main h2 {font-size: 30px;margin: 0px;}
.privacy_policy_wrapper .privyc_header {float: left;width: 100%;margin: 0 0 30px;}
.privacy_policy_wrapper .privyc_header h4 {float: left;width: 100%;margin: 0px 0px 15px;font-size: 17px;}
.privacy_policy_wrapper .privyc_header p {float: left;width: 100%;margin: 0 0 0;}
.privacy_policy_wrapper .privyc_body {float: left;width: 100%;margin: 0 0 30px;}
.StockDetailsReportPage {float: left;width: 100%;position: relative;}
.StockDetailsReportPage .filter_stock_inward.active {width: auto;position: absolute;right: 0px;top: 46px;}
.privacy_policy_wrapper .privyc_body h4 {float: left;width: 100%;margin: 0px 0px 15px;font-size: 17px;}
.privacy_policy_wrapper .privyc_body p {float: left;width: 100%;margin: 0 0 0;}
.privacy_policy_wrapper ul, .privacy_policy_wrapper  li {float: left;width: 100%;}
.privacy_policy_wrapper  ul li {float: left;width: 100%;margin-bottom: 6px;font-size: 15px;}
.privacy_policy_wrapper .privacy_main_body {float: left;width: 100%;padding: 0px 40px;box-sizing: border-box;}
/* privacy policy page css */

/* tickets verify css here */
.verify_tickets_wrapper {float: left;width: 100%;position: relative;display: flex;column-gap: 5px;}
.verify_tickets_wrapper .verify_wrapper {float: left;width: 100%;position: relative;}
.verify_tickets_wrapper .verify_tickets_wrapper_btns {float: left;width: auto;display: flex;column-gap: 2px;}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .spinner-border {width: 19px;height: 19px;border-radius: 100%;border-width: 3px;float: left;margin: 6px 5px 0px 2px;}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .verify_tickets_btn {margin-right: 3px;}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .btn {font-size: 13px;padding: 5px 10px;width: auto;background-color: #E0EEFF !important;border-color: #E0EEFF !important;color: #333;}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .reset_btn {background-color: transparent !important;border-color: #DEE2E5 !important;}
.verify_tickets_wrapper .verify_loader {float: left;width: auto;position: absolute;right: 8px;top: 8px;}
.verify_tickets_wrapper .verify_loader .spinner-border {float: left;width: 22px;height: 22px;border-width: 3px;}
.verify_tickets_wrapper .verify_wrapper input {padding: 0.375rem 35px 0.375rem 0.75rem;}
.tickets_verifications_wraps ul li {float: left;width: 100%;font-size: 15px;padding-bottom: 4px;}
.tickets_verifications_wraps ul {float: left;width: 100%;margin-bottom: 0px;padding-left: 20px;}
.tickets_verifications_wraps ul li label {float: left;width: auto;font-weight: 500;}
/* tickets verify css here */
.spares_input_box {display: flex;align-items: center;column-gap: 7px;}
.spares_input_box input {width: 95px;border-radius: 4px;font-size: 14px;padding: 7px 9px;box-shadow: none !important;}

/* common datepicker css here */
.DashboardMainHeader .datepickField_new .react-datepicker-wrapper {float: left;border-radius: 4px;overflow: hidden;box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important;}
.DashboardMainHeader .datepickField_new .react-datepicker__input-container input {height: 32px !important;font-size: 13px;border: 0px !important;border-radius: 4px;outline: none !important;padding: 5px 38px 5px 10px;background-image: url("../img/calendar_icons.svg");background-repeat: no-repeat;background-position: 95% center;width: 100%;}
.filtr_Form .datepickField_new .react-datepicker-wrapper {float: left;border-radius: 4px;overflow: hidden;}
.filtr_Form .datepickField_new .react-datepicker__input-container input {border: 1px solid #DEE2E5 !important;height: 38px !important;font-size: 13px;border-radius: 4px;outline: none !important;padding: 5px 38px 5px 10px;background-image: url("../img/calendar.png");background-repeat: no-repeat;background-position: 95% center;width: 100%;background-size: 16px;}
.datepickField_new .react-datepicker__close-icon::after {display: none;}
.datepickField_new .react-datepicker__close-icon {padding-right: 0px;right: 33px;padding-top: 2px;}
.datepickField_new .react-datepicker__close-icon::before {content: "\F622";font-family: 'bootstrap-icons';font-size: 13px;color: red;}
.datepickField_new .react-datepicker-popper .react-datepicker,
.datepickField_new .react-datepicker-popper .react-datepicker .react-datepicker__header {border-color: #dfdfdf !important;}
/* common datepicker css here */

/* logout modal design */
.logout_modal_main .modal-dialog {width: 440px;}
.logout_modal_main .modal-dialog .modal-content {position: relative;text-align: center;}
.logout_modal_main .modal-dialog .modal-header {z-index: 999;padding: 0px;border: 0px !important;position: absolute;right: 0;top: 0;}
.logout_modal_main .modal-dialog .modal-header .btn-close {margin: 5px;}
.logout_modal_main .modal-dialog .modal-body {text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;row-gap: 10px;padding-top: 30px;} 
.logout_modal_main .modal-dialog .logout_icons {width: 60px;float: none;height: 60px;border-radius: 100%;font-size: 24px;display: flex;align-items: center;justify-content: center;color: #004dac;background-color: #E0EEFF;margin: 0 0 4px;}
.logout_modal_main .modal-dialog h4 {margin: 0 0 2px;font-weight: 600;font-size: 26px;}
.logout_modal_main .modal-dialog p {margin: 0px;font-size: 16px;}
.logout_modal_main .modal-dialog .modal-footer {justify-content: center;padding: 13px;}
.logout_modal_main .modal-dialog .modal-footer .btn {padding: 7px 10px !important;width: 100px;border-radius: 4px !important;margin: 0px;}
.logout_modal_main .modal-dialog .yes_btn {background-color: #E0EEFF;border-color: #E0EEFF;color: #004dac;margin-right: 10px;}
.logout_modal_main .modal-dialog .yes_btn:hover, .logout_modal_main .modal-dialog .yes_btn:focus {background-color: #004dac !important;border-color: #004dac !important;color: #fff;}
.logout_modal_main .modal-dialog .no_btn {background-color: transparent !important;border-color: #DEE2E5 !important;margin-left: 10px !important;color: #333 !important;}
/* logout modal design */

/* permission modal css start here */
.commonModal.permissionmodel .table>thead {position: relative !important;box-shadow: none !important;}
.so_creations_details .table>thead, .warehouse_Approval_details .table>thead {position: relative !important;}
.commonModal.permissionmodel {border: 1px solid #ececec;border-radius: 6px;}
.commonModal.permissionmodel .table thead tr th:first-child {border-top-left-radius: 5px;border-right: 1px solid #ececec;}
.commonModal.permissionmodel .table thead tr th:last-child {border-top-right-radius: 5px;border-left: 1px solid #ececec;}
.commonModal.permissionmodel .table tbody tr td {border-color: #ececec;}
.commonModal.permissionmodel .table tbody tr:last-child td {border-bottom: 0px;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;}
.commonModal.permissionmodel .table {margin-bottom: 0px;}
.commonModal.permissionmodel .tableView {margin-bottom: 20px;border: 1px solid #ececec;border-radius: 5px;}
.commonModal.permissionmodel .table tbody tr td:first-child {border-right: 1px solid #ececec;}
.commonModal.permissionmodel .table tbody tr td:last-child {border-left: 1px solid #ececec;}
/* permission modal css end here */

.backbtnnew {float: left;border-radius: 4px;overflow: hidden;box-shadow: 0px 2px 5.5px 0px rgba(0, 0, 0, 0.06) !important;background-color: #ffff !important;color: #333 !important;line-height: normal;border: 1px solid #cacaca !important;padding: 5.5px 10px !important;font-size: 14px !important;}
.commonModalActiveAssociatesModal .modal-dialog {max-width: 640px;}
.commonModalAvailableFrameInventory .modal-dialog {max-width: 740px;}
.Active-Associates-btn {color: #110AA5 !important;text-decoration: none !important;font-weight: 500 !important;font-size: 13px !important;line-height: normal !important;padding: 0px !important;}
.common_piechart_wrapper {float: left;width: 100%;position: relative;}
.piechart_not_found {align-items: center;display: flex;justify-content: center;flex-direction: column;width: 100%;height: 100%;}
.piechart_not_found .bi-pie-chart {font-size: 25px;}
#order_details_from .col-md-3 {width: 24%;}
#table_order_details_main .table>thead, .FranchiseOrdersTablesDetails .table .table>thead {position: relative !important; z-index: 1;}
#table_order_details_main input {border-radius: 4px;height: 38px;width: 100%;font-size: 13px;box-shadow: none !important;border: 1px solid #dee2e6 !important;padding: .375rem .75rem;outline: none !important;}
#stock_level_so_approval #react-select-2-input, #stock_level_so_approval #react-select-3-input {border: 0px !important;}
.ScrollbarsSidebarHorizontal .Scrollbars__horizontalScroll {display: none;}
.form-control:disabled {background-color: #F8F9FD !important;cursor: no-drop;}
.selectMultiselect.selectMultiselectAllow .godown-select__placeholder {position: absolute;left: 0;top: 5px;}

/* order log modal css start here */
.order_logs_box {float: left;width: 108% !important;display: flex;align-items: center;background-color: #f8fbff;border-radius: 8px;margin: 0 0 20px;border: 1px solid #9ac7ff;padding: 8px 8px 8px 62px !important;position: relative;}
/* order log modal css end here */

#Create-Sales-Invoice-button {padding: 9px 17px 9px 34px !important;}
#Create-Sales-Invoice-button i {top: 7px;}
.reassign_btn_wrapper #cancelOrderSet {background-color: initial !important;border-color: #dee2e5 !important;color: #333 !important;font-size: 16px;padding: 8px 14px;}
.commonModal .modal-dialog textarea.form-control {border-color: #dee2e6;box-shadow: none !important;border-radius: 4px;}
.Bike_Condition.table>thead {position: relative;z-index: initial;}
.schemes_Main_section .datepickField input {background-position: 93% center;}
.Order_pause_commonModal .modal-dialog, 
#order_return_replacment_accept {max-width: 390px;}
.returnAndReplaceTableModules .btn-primary, .returnAndReplaceTableModules .btn-danger {padding: 7px 22px !important;font-size: 13px !important;border-radius: 100px !important;letter-spacing: 0.5px !important;}
#order_return_replacment_accept_new .Order_Accept_btn_group {display: flex !important;float: left;width: 100%;column-gap: 12px;}
#order_return_replacment_accept_new .Order_Accept_btn_group .skipbtn {font-size: 16px !important;}
.modal.show .modal-dialog .card-header-New .userLimitselect {border: 1px solid #ced4da !important;}
#afs_btn_functions {padding: 6px 10px;margin: 0 5px;}
.QuotationPaymentApprovalcommonModal .modal-dialog {max-width: 430px;}
.QuotationPaymentApprovalcommonModal .modal-dialog .datepickField input {background-position: 96% center;}
.QuotationPaymentApprovalcommonModal .modal-dialog .formloginset .mb-3 {margin-bottom: 10px !important;}
#UploadCmnBTNnew {background-color: #E0EEFF !important;border-color: #E0EEFF !important;color: #333 !important;}
.btn-copy-link {font-size: 13px !important;border-radius: 4px !important;background: transparent !important;color: #333 !important;border-color: #dee2e6 !important;padding: 5px 11px !important;margin-left: 10px;}
.btn-copy-link.active {color: #004DAC !important;border-color: #004DAC !important;}
.Main_section_Quotations .tableView tbody .table.table-bordered thead {position: relative !important;z-index: 1 !important;}
.attachment_listin_payment {padding-left: 20px;margin-bottom: 0px;}
.attachment_listin_payment li {cursor: pointer;margin: 0 0 4px;}
.commonModalUpdate-Stock .modal-dialog {max-width: 400px;}

/* common address css start here */
.commonAddress_Valid {float: left;width: 100%;position: relative;}
.commonAddress_Valid .address_limit {float: left;width: auto;background-color: #333;color: #fff;font-size: 11px;border-radius: 30px;padding: 3px 7px;box-sizing: border-box;line-height: normal;position: absolute;right: 5px;bottom: 6px;}
/* common address css end here */

#OrderDetailsModalNew .btn-secondary {padding: 8px 18px !important;font-size: 15px;}
.Toastify__toast--info .Toastify__progress-bar--wrp .Toastify__progress-bar--info {background-color: #004DAC !important;}
.Toastify__toast--info .Toastify__toast-icon path {fill: #004DAC !important;color: #004DAC !important;}
.cmmn-logut-btn {float: left;width: 90.5%;background-color: #E0EEFF !important;color: #000000 !important;border-color: #E0EEFF !important;padding: 8px 15px 8px 15px !important;position: absolute;bottom: 13px;left: -3px;right: 0;margin: 0 auto;font-size: 14px !important;line-height: normal !important;}
.Header-Minimize-Toggle {padding: 0px !important;background-color: transparent !important;color: #333 !important;border: 0px !important;font-weight: 700 !important;line-height: normal !important;margin-right: 10px;float: left;}
.sidebarWrapper.close {border-radius: 4px;}
.sidebarWrapper.close .navLogo {padding: 9.5px 10px 9.5px;}
.sidebarWrapper.close .nav-logo img {width: 45px;}
.sidebarWrapper.close .sidebarMain {padding: 0px 3px;margin: 4px 0 0;}
.sidebarWrapper.close .sidebarMain .flex-column.nav {flex-direction: column !important;} 
.sidebarWrapper.close .sidebarMain .flex-column.nav .nav-link {width: 100% !important;border-radius: 3px !important;margin: 0 0 5px;}
.sidebarWrapper.close .cmmn-logut-btn {left: 0px;border-radius: 3px !important;color: #004DAC !important;font-size: 18px !important;}
.mini-logo-icons {display: none;}
.sidebarWrapper.close .nav-logo .mini-logo-icons {display: block;}
.sidebarWrapper.close .nav-logo .logo-icons {display: none;}

/* Firefox-specific CSS */
@supports (-moz-appearance: none) {
    .ScrollbarsSidebar.ScrollbarsSidebar-active .view {margin-right: 2px !important;}
};
@-moz-document url-prefix() {
    .ScrollbarsSidebar.ScrollbarsSidebar-active .view {margin-right: 2px !important;}
};
@media screen and (-moz-images-in-menus:0) {
    .ScrollbarsSidebar.ScrollbarsSidebar-active .view {margin-right: 2px !important;}
};